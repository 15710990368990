
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';

const storeitemMutation = gql`
  	mutation updateStoreItem(
		$storeitemId: Int, $storeitemTitle: String, $storeitemPrice: String,
		$storeitemDescription: String, $storeitemStock: Int,
		$storeitemImage: String, $storeitemIsdigital: String,
		$isDeleted: Int,
	) {
    	updateStoreItem(
			storeitemId: $storeitemId, storeitemTitle: $storeitemTitle, storeitemPrice: $storeitemPrice,
			storeitemDescription: $storeitemDescription, storeitemStock: $storeitemStock,
			storeitemImage: $storeitemImage, storeitemIsdigital: $storeitemIsdigital,
			meta_isDeleted: $isDeleted
		) {
			storeitemId
			storeitemTitle
			storeitemPrice
			storeitemDescription
			storeitemStock
			storeitemStockPercentage
			storeitemImage
			storeitemIsdigital
		}
  	}
`

@Injectable()
export class StoreService implements OnDestroy {

	public loading = true;
	public storeitems = new BehaviorSubject<any>(null);
	private sub: Subscription;

	public static lastVars = null;
	public static storeitemQuery = gql`
	query storeitemsQuery(
		$storeitemId: [Int]
	)
	{
		storeitems(storeitemId: $storeitemId)
		{
			storeitemId
			storeitemTitle
			storeitemPrice
			storeitemDescription
			storeitemStock
			storeitemStockPercentage
			storeitemImage
			storeitemIsdigital
		}
	}
`

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.getStoreItem();
	}

	getStoreItem(storeitemIds: number[] = null) {

		StoreService.lastVars = {
			storeitemId: storeitemIds,
		}

		return new Promise((resolve, reject) => {
			this.sub = this.apollo.watchQuery({
				query: StoreService.storeitemQuery,
				variables: StoreService.lastVars,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.storeitems.next(JSON.parse(JSON.stringify(data['storeitems'])));
				resolve(this.storeitems.value);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		})
	}

	updateStoreItems(storeitem: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: storeitemMutation,
				variables: {
					storeitemId: storeitem.storeitemId,
					storeitemTitle: storeitem.storeitemTitle,
					storeitemPrice: storeitem.storeitemPrice,
					storeitemDescription: storeitem.storeitemDescription,
					storeitemStock: storeitem.storeitemStock,
					storeitemImage: storeitem.storeitemImage,
					storeitemIsdigital: (storeitem.storeitemIsdigital) ? 1 : 0,
					isDeleted: storeitem.isDeleted ? 1 : 0,
				},
				refetchQueries: [
					{
						query: StoreService.storeitemQuery,
						variables: {
							storeitemId: null,
						},
					},
				],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
