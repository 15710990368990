import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentsService } from '../../../@core/data/store/payments.service';
import { ClientService } from '../../../@core/data/clients/client.service';
import { CompleterData, CompleterService } from 'ng2-completer';

@Component({
	selector: 'ngx-paymentadd',
	styleUrls: ['./paymentadd.component.scss'],
	templateUrl: './paymentadd.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class PaymentAddComponent implements OnInit, OnDestroy {
		public loading = false;
		public paramSub: Subscription;

		//
		protected clientCompleter: CompleterData;

		// load in clients for the charge
		public clientList = null;
		public appointmentList = null;

		// build a payment object for the form
		public payment: any = {
			paymentClient: null,
			paymentAmount: 0,
			paymentMethod: 0,
			paymentAppointment: 0,
			paymentMethods: [
				/*{key: 'stripe', value: 'Card'},*/
				{key: 'stripeSaved', value: 'Saved Card'},
				{key: 'cash', value: 'Cash'},
			],
		};

		@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
		@Input() showClose: boolean = false;

		constructor(
				private clientService: ClientService,
				private paymentsService: PaymentsService,
				private completerService: CompleterService,
				private route: ActivatedRoute,
				private router: Router,
		) {}

		public ngOnInit(): void {

			this.paramSub = this.route.params.subscribe(params => {

				if (params['paymentAmount']) {
					this.payment.paymentAmount = +params['paymentAmount'];
				}

				if (params['paymentClient']) {
					this.onClientChange(+params['paymentClient']);
				}

				if (params['paymentAppointment']) {
					this.payment.paymentAppointment = +params['paymentAppointment'];
				}
			});

			this.clientService.getClients().then(clientData => {
				if (clientData) {
					// Consume
					if (this.payment.paymentClient) {
						this.payment.paymentClientInfo = clientData.find(client => client.clientId === this.payment.paymentClient).clientName;
					}
					this.clientCompleter = this.completerService.local(clientData, 'clientName', 'clientName');
				}
			});
		}

		public closeModal(): void {
			this.formSubmit.next(false);
		}

		public onClientChange(clientId): void {
			this.payment.paymentClient = clientId;
			this.clientService.getAppointments(clientId).then(appointmentList => {
				console.log('appointmentList', this.appointmentList);
				this.appointmentList = appointmentList.upcomingAppointments.concat(appointmentList.appointmentHistory);
			});
		}

		public initPayment(paymentInfo): void {

			console.log('initPayment', paymentInfo)
			// todo
			// stripe checkout
			this.loading = true;
			this.paymentsService.updatePayment(paymentInfo).then((newPayment) => {
				if (newPayment) {
					this.loading = false;
					this.router.navigateByUrl('/pages/payments/' + newPayment.updatePayment[0].paymentId);
				}
			}).catch((failure) => {
				this.loading = false;
			});
		}

		public toDate(inputDate: string): Date {
			return new Date(inputDate.replace(/-/g, '/'));
		}

		public ngOnDestroy(): void {
			this.paramSub.unsubscribe();
			if (this.paramSub) { this.paramSub.unsubscribe(); };
		}

}
