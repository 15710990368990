
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';

const paymentMutation = gql`
mutation updatePayment($paymentId: Int, $paymentClient: Int, $paymentAppointment: Int,
					$paymentAmount: String, $paymentMethod: String, $paymentNotes: String, $paymentOrder: Int) {
	updatePayment(paymentId: $paymentId, paymentClient: $paymentClient, paymentAppointment: $paymentAppointment,
					paymentAmount: $paymentAmount, paymentMethod: $paymentMethod, paymentNotes: $paymentNotes, paymentOrder: $paymentOrder) {
        paymentId
		paymentAppointment
		paymentAppointmentName
		paymentClient
		paymentClientName
		paymentTimestamp
        paymentAmount
        paymentNotes
        paymentOrder
    }
}
`

const paymentsQuery = gql`
query payments($paymentId: Int) {
    payments(paymentId: $paymentId) {
        paymentId
		paymentAppointment
		paymentAppointmentName
		paymentClient
		paymentClientName
        paymentTimestamp
        paymentAmount
        paymentNotes
        paymentOrder
    }
}
`

@Injectable()
export class PaymentsService implements OnDestroy {

	public loading = true;
	public payments = new BehaviorSubject<any>(null);
	private sub: Subscription;
	private lastVars = null;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public getPayments(paymentIds: any = null): void {

		paymentIds = (paymentIds) ? (paymentIds).join(',') : null;

		this.lastVars = {
			paymentId: paymentIds,
		};

		this.sub = this.apollo.watchQuery({
			query: paymentsQuery,
			variables: this.lastVars,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.payments.next(JSON.parse(JSON.stringify(data['payments'])));
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public updatePayment(payment: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: paymentMutation,
				variables: {
					paymentId: payment.paymentId,
					paymentAppointment: payment.paymentAppointment,
					paymentClient: payment.paymentClient,
					paymentMethod: payment.paymentMethod,
					paymentAmount: payment.paymentAmount,
					paymentNotes: payment.paymentNotes,
					paymentOrder: payment.paymentOrder,
				},
				refetchQueries: [{
					query: paymentsQuery,
					variables: this.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
