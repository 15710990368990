import { Component, Input, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';

import { NbSidebarService, NbMenuItem, NbSearchService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users/user.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AlertService } from '../../../@core/data/globals/alerts.service';
import { DOCUMENT } from '@angular/common';
import { ModalService } from 'app/@core/data/globals/modal.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'app/@core/data/settings/settings.service';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() position = 'normal';

	public userSub: Subscription;
	public alertSub: Subscription;
	public hasNews: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public user: any;

	skeletonMenu: NbMenuItem[] = [
		{
			title: 'Loading...',
		},
	];

	nodataMenu: NbMenuItem[] = [
		{
			title: 'Nothing Found!',
		},
	];

	userMenu: NbMenuItem[] = this.skeletonMenu;
	alertsMenu: NbMenuItem[] = this.skeletonMenu;
	lastViewedNewsDate: number;

	constructor(
		private sidebarService: NbSidebarService,
		private settingsService: SettingsService,
		private userService: UserService,
		private alertService: AlertService,
		private searchService: NbSearchService,
		private modalService: ModalService,
		private http: HttpClient,
		@Inject(DOCUMENT) private document: Document) {}

	public ngOnInit() {
		this.userSub = this.userService.user.subscribe(value => {
			if (value != null) {
				this.user = (value);
				this.userMenu = [
					{
						title: 'My Profile' ,
						link: '/pages/settings/users/' + this.user.userId,
					},
					{
						title: 'Help Center',
						link: '/pages/help',
					},
					{
						title: 'Log out' ,
						link: '/auth/logout/',
					},
				];
			}
		});

		// last viewed alerts is actually blog
		this.settingsService.getSettingValueAsPromise('lastViewedNews').then(lastViewedNews => {	
			this.lastViewedNewsDate = Date.parse(lastViewedNews);		
			this.checkHasNewNews().then(torf => {
				this.hasNews.next(torf);
			});
		})

		this.alertSub = this.alertService.alerts.subscribe(value => {
			if (value != null) {

				if (value.length < 1) {
					return this.nodataMenu;
				}

				// fixed in new dashboard...
				// rename props to expected names for nb plugin
				const renamed = value;
				renamed.forEach((part, index, renamed) => {
					renamed[index].title = renamed[index].alertTitle;
					renamed[index].link = renamed[index].alertLink;
					if (renamed[index].alertUnread) {
						renamed[index].title = `UNREAD: ${renamed[index].title}`;
						renamed[index].badge = {
							dotMode: true,
							status: 'warning',
						}
					}
				});

				renamed.push({title: 'View More', link: '/pages/alerts/'});
				renamed.push({title: 'Mark All As Read', link: '/pages/alerts/', queryParams: {markallasread: 1}});

				this.alertsMenu = renamed;
			}
		});
	}

	public ngAfterViewInit() {
		this.startSearch();
	}

	private checkHasNewNews(): Promise<boolean>{
		return new Promise<boolean>((resolve)=> {
			this.http.get('https://kibook.co.uk/wp/wp-json/wp/v2/posts?per_page=1&_fields[]=date', {responseType: 'text'})
			.subscribe(data => {
				const lastPostDate = Date.parse(JSON.parse(data)[0].date);
				resolve(this.lastViewedNewsDate < lastPostDate)
			});
		})
		
	}

	public showNews(): void {
		this.hasNews.next(false);
		this.settingsService.updateSettings([{settingName: 'lastViewedNews', settingValue: (new Date()).toISOString()}]);
		console.log('setting', (new Date()).toISOString());

		this.http.get('https://kibook.co.uk/wp/wp-json/wp/v2/posts', {responseType: 'text'})
        .subscribe(data => {
			
			// prep data
			let feedData = JSON.parse(data);	
			feedData.forEach(element => {
				element.isNew = Date.parse(element.date) > this.lastViewedNewsDate;
			});

			// show popup
			this.modalService.newModal(
				'KiBook News!',
				`
				<div
					*ngFor="let y of contextRef"
					class="newsEntry"
					[class.active]="y.isNew"
				>
					<a target="blank" href="{{y.link}}">
						<h5>{{y.title.rendered}}</h5>
					</a>
					<small>{{y.date}}</small>
				</div>
				`, null, feedData, true,
			);
		});
	}

	public ngOnDestroy(): void {
		if (this.userSub) { this.userSub.unsubscribe(); };
	}

	public toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		return false;
	}

	private startSearch() {
		// need to hijack whole layout to watch for enter key on search input
		// this is a fix for iOS
		this.document.body.querySelector('body > ngx-app > ngx-pages > ngx-sample-layout > nb-layout').addEventListener('keyup', (event: any) => {
			if (Number(event.keyCode) === 13) {
				let searchFocused = event.target.classList.contains("search-input");
				if (searchFocused) {
					event.preventDefault();
					let searchField = <HTMLInputElement>this.document.body.querySelector('.search input');
					this.searchService.submitSearch(searchField.value);
					this.searchService.deactivateSearch("");
				}
				
			}
		})
	}
}
