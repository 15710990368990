import {Component, Input, ElementRef} from '@angular/core';
import { NbThemeService, NbPopoverDirective } from '@nebular/theme';
import { NbJSThemeOptions } from '@nebular/theme/services/js-themes/theme.options';
import { SettingsService } from '../../../../@core/data/settings/settings.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'ngx-theme-switcher-list',
	template: `
    <ul class="themes-switcher-list">
      <li class="themes-switcher-item"
          *ngFor="let theme of themes"
          (click)="onToggleTheme(theme.key)">
        <i class="eva eva-droplet-outline" [ngClass]="'drop-icon-' + theme.key"></i>
		<span>{{ theme.title }}</span>
	  </li>

	  <li class="themes-switcher-item">
	  	<i class="eva eva-droplet-outline" [ngClass]="'drop-icon-custom'" [style.background]="KiThemeColour"></i>
	  	<span class="theme-switcher"
			[cpPresetColors]="[defaultColour, '#ffd1dc', '#AEC6CF']"
			[cpOKButton]="true"
			[cpSaveClickOutside]="false"
			[cpAddColorButton]="true"
			[(colorPicker)]="kiThemeColour">
			Custom
		</span>
		</li>
    </ul>
  `,
	styleUrls: ['./theme-switcher-list.component.scss'],
})
export class ThemeSwitcherListComponent {

	@Input() popover: NbPopoverDirective;

	theme: NbJSThemeOptions;

	themes = [
		{
			title: 'Original',
			key: 'default',
		},
		{
			title: 'Light',
			key: 'light',
		},
		{
			title: 'Dark',
			key: 'dark',
		},
	];

	public defaultColour = '#5f9ea0';
	private _kiThemeColour: string;
	get kiThemeColour(): string {
		return this._kiThemeColour;
	}
	set kiThemeColour(newKiThemeColour: string) {
		this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary', newKiThemeColour);
		if (!environment.forwardsCompatibilityMode)
        {
            this.settingsService.setSettingValue('KiThemeColour', newKiThemeColour);
        }
		this._kiThemeColour = newKiThemeColour;
		this.onToggleTheme('custom', false);
	}

	constructor(
		private themeService: NbThemeService,
		private elementRef: ElementRef,
		private settingsService: SettingsService,
	) {}

	onToggleTheme(themeKey: string, popoverToggle: boolean = true) {

		// change theme
		this.themeService.changeTheme(themeKey);

		// save
        if (!environment.forwardsCompatibilityMode) {
            this.settingsService.updateSettings([{settingName: 'KiDefaultTheme', settingValue: themeKey}, {settingName: 'KiThemeColour', settingValue: this.kiThemeColour}]);
        }

		if (popoverToggle) {
			this.popover.hide();
		}
	}
}
