import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '../../../../../@core/data/services/categories.service';

@Component({
	selector: 'ngx-categoryview',
	styleUrls: ['./categoryview.component.scss'],
	templateUrl: './categoryview.component.html',
})
export class CategoryViewComponent implements OnInit, OnDestroy {

		public category: any = {};

		@Input()
		public id;

		public loading = true;
		public dataSub: Subscription;
		public paramSub: Subscription;

		//
		@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
		@Input() showClose: boolean = false;

		constructor(
				private categoriesService: CategoriesService,
				private route: ActivatedRoute,
		) {}

		public ngOnInit(): void {

			this.paramSub = this.route.params.subscribe(params => {
				if (params['id'] === undefined || params['id'] === 'new') {
					this.category = {
						apptcatEnabled: 1,
					};
				}

				this.id = (params.id) ? +params['id'] : this.id; // (+) converts string 'id' to a number

				if (this.id > 0) {
					this.categoriesService.getCategory(this.id);
					this.dataSub = this.categoriesService.categories.subscribe(value => {
						if (value) {
							this.category = value.find(cat => Number(cat.apptcatId) === Number(this.id));
							console.log('this.category', this.category, value)
						}
					});
					console.log(this.categoriesService);
				}
			});
		}

		public save(): void {
			this.categoriesService.updateCategory(this.category).then((updatedCategory) => {
				console.log('result', updatedCategory)
				this.formSubmit.next(true);
			});
		}

		public delete(): void {

			if (confirm('Are you sure?')) {
				this.category.isDeleted = true;

				this.categoriesService.updateCategory(this.category).then((updatedCategory) => {
					console.log('deleted', updatedCategory)
					this.closeModal();
				});
			}
		}

		public closeModal(): void {
			this.formSubmit.next(false);
		}

		public ngOnDestroy(): void {
			if (this.dataSub) {
				this.dataSub.unsubscribe();
			}
			if (this.paramSub) {
				this.paramSub.unsubscribe();
			}
		}
}
