import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { UserService } from '../../../@core/data/users/user.service';
import { ServicesService } from '../../../@core/data/services/services.service';
import { CategoriesService } from '../../../@core/data/services/categories.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomerService } from '../../../@core/data/settings/customer.service';

@Component({
	selector: 'ngx-tutorialcomponent',
	template: `
	<div class="row">
		<div class="col-lg-12">
			<nb-card>
				<nb-card-header>
					KiBook Tutorial
					<button *ngIf="showClose" class="close" aria-label="Close" (click)="closeModal();">
						<span aria-hidden="true">&times;</span>
					</button>
				</nb-card-header>
				<nb-card-body>

				  <nb-stepper orientation="horizontal">
					<nb-step label="Welcome!">
					  <h4>Welcome to KiBook!</h4>
						<div class="row">
							<div class="col-6">
								<p>Your account is ready to go, these next steps will help you complete your set up! 🎉</p>
								<p>Click next to begin inputting your services, working hours and staff information! Don't forget to bookmark this page or add it to your home screen.</p>
							</div>
							<div class="col-6">
								<img src="./assets/svg/setup.svg"/>
							</div>
						</div>
					  <button nbButton nbStepperNext>next</button>
					</nb-step>
					<nb-step label="Staff" *ngIf="customerData?.customerTier > 1">
					  	<h4>Staff Members</h4>
						<p>If you have additional members of staff you can add them here!</p>
						<p>Staff can have their own passwords to login to access or receive bookings through KiBook - don't worry you can change anything later!</p>
						<ng2-smart-table [settings]="userTableSettings" [source]="userTableData" (createConfirm)="confirmUserTableCreate($event)" (editConfirm)="confirmUserTableEdit($event)" (deleteConfirm)="confirmUserTableDelete($event)">
                		</ng2-smart-table>
						<button nbButton nbStepperPrevious>prev</button>
						<button nbButton nbStepperNext>next</button>
					</nb-step>

					<nb-step label="Schedule">
						<h4>Working Hours</h4>
						<p>Add your regular working hours in here that you would like clients to be able to book.</p>
						<p>You can change your hours anytime on your KiBook calendar for overtime, annual leave and breaks!</p>
						<ngx-schedule></ngx-schedule>
						<button nbButton nbStepperPrevious>prev</button>
						<button nbButton nbStepperNext>next</button>
					</nb-step>

					<nb-step label="Services">
						<h4>Services</h4>
						<p>
							Let's get a quick list of the services you offer
						</p>
						<p>You can always add more later!</p>
						<ng2-smart-table [settings]="serviceTableSettings" [source]="serviceTableData" (createConfirm)="confirmServiceTableCreate($event)" (editConfirm)="confirmServiceTableEdit($event)" (deleteConfirm)="confirmServiceTableDelete($event)">
                		</ng2-smart-table>
						<button nbButton nbStepperPrevious>prev</button>
						<button nbButton nbStepperNext>next</button>
					</nb-step>

					<nb-step label="Done!">
					  <h4>You did it!</h4>
					  <div class="row">
						<div class="col-6">
							<img style="width:90%;margin:10px;"src="./assets/svg/party.svg"/>
					  	</div>
						<div class="col-6">
							<p>
								And with that, you're ready to go!🙌

								You can start taking appointments via your own custom link:
							</p>
							<a style="text-align:center;color:cadetblue;font-weight:bold;"
								href="{{this.getBookingLink()}}"
								target="_blank"
							>
								{{this.getBookingLink()}}
							</a>
							<p>
								Make sure to share it on your website and social media!
							</p>
							<iframe [src]="this.getFacebookLink()" width="77" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
						</div>
					  </div>
					  <button nbButton nbStepperPrevious>prev</button>
					  <button class="btn btn-success" (click)="tutorialComplete()">done!</button>
					</nb-step>
				  </nb-stepper>
				</nb-card-body>
			</nb-card>
		</div>
	</div>
	`,
})

export class TutorialComponent implements OnInit {

	@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
	@Input() showClose: boolean = false;

	public customerData;

	public userTableData: LocalDataSource = new LocalDataSource();
	public userTableSettings = {
		add: {
			addButtonContent: '<i class="eva plus-circle-outline"></i>',
			createButtonContent: '<i class="eva eva-checkmark-circle-outline"></i>',
			cancelButtonContent: '<i class="eva eva-close-circle-outline"></i>',
			confirmCreate: true,
		},
		edit: {
			editButtonContent: '<i class="eva eva-edit-outline"></i>',
			saveButtonContent: '<i class="eva eva-checkmark-circle-outline"></i>',
			cancelButtonContent: '<i class="eva eva-close-circle-outline"></i>',
			confirmSave: true,
		},
		delete: {
			deleteButtonContent: '<i class="eva eva-trash-2-outline"></i>',
			confirmDelete: true,
		},
		actions: {
			add: true,
			edit: false,
			delete: false,
			position: 'left',
		},
		columns: {
			userUsername: {
				title: 'Username',
				type: 'string',
				filter: false,
				width: '90%',
			},
		},
	};

	public serviceTableData: LocalDataSource = new LocalDataSource();
	public serviceTableSettings = {
		add: {
			addButtonContent: '<i class="eva plus-circle-outline"></i>',
			createButtonContent: '<i class="eva eva-checkmark-circle-outline"></i>',
			cancelButtonContent: '<i class="eva eva-close-circle-outline"></i>',
			confirmCreate: true,
		},
		edit: {
			editButtonContent: '<i class="eva eva-edit-outline"></i>',
			saveButtonContent: '<i class="eva eva-checkmark-circle-outline"></i>',
			cancelButtonContent: '<i class="eva eva-close-circle-outline"></i>',
			confirmSave: true,
		},
		delete: {
			deleteButtonContent: '<i class="eva eva-trash-2-outline"></i>',
			confirmDelete: true,
		},
		actions: {
			add: true,
			edit: false,
			delete: false,
			position: 'left',
		},
		columns: {
			serviceName: {
				title: 'Service Name',
				type: 'string',
				filter: false,
				width: '90%',
			},
			serviceCategoryName: {
				title: 'Category',
				type: 'string',
				filter: false,
				width: '90%',
			},
			servicePrice: {
				title: 'Price',
				type: 'string',
				filter: false,
				width: '90%',
			},
			serviceDeposit: {
				title: 'Deposit',
				type: 'string',
				filter: false,
				width: '90%',
			},
			serviceLength: {
				title: 'Length in Minutes',
				type: 'string',
				filter: false,
				width: '90%',
			},
		},
	};

	constructor(
		private customerService: CustomerService,
		private userService: UserService,
		private servicesService: ServicesService,
		private categoriesService: CategoriesService,
		private domSantizer: DomSanitizer,
	) {}

	public confirmUserTableCreate(event): void {

		// confirm with user
		if (window.confirm('Are you sure?')) {

			// Ensure the user 'provides all services'
			event.newData.userServices = '0';

			// save the changes
			this.userService.updateUser(event.newData).then(newUserResult => {

				// resolve with results
				console.log('newUserResult', newUserResult[0]);
				event.confirm.resolve(newUserResult[0]);
			});
		} else {
			event.confirm.reject();
		}
	}

	public confirmServiceTableCreate(event) {
		// confirm with user
		if (window.confirm('Are you sure?')) {

			// enable new service
			event.newData.serviceEnabled = 1;

			const existingCategory = this.categoriesService.categories.value.find(cat => event.newData.serviceCategoryName.toLowerCase().includes(cat.apptcatName.toLowerCase()));
			if (existingCategory) {

				// set the category
				event.newData.serviceCategory = existingCategory.apptcatId;

				// save the changes
				this.servicesService.updateService(event.newData).then(newServiceResult => {

					// resolve with results
					console.log('newServiceResult', newServiceResult[0]);
					event.confirm.resolve(newServiceResult[0]);
				});

			} else {
				const newCategory = {
					apptcatName: event.newData.serviceCategoryName,
					apptcatEnabled: 1,
				}

				this.categoriesService.updateCategory(newCategory).then(updatedCategory => {

					// set the category
					event.newData.serviceCategory = updatedCategory[0].apptcatId;

					// save the changes
					this.servicesService.updateService(event.newData).then(newServiceResult => {

						// resolve with results
						console.log('newServiceResult', newServiceResult[0]);
						event.confirm.resolve(newServiceResult[0]);
					});
				});
			}

		} else {
			event.confirm.reject();
		}
	}

	public tutorialComplete(): void {
		this.customerService.updateCustomer({customerId: this.customerData.customerId, customerTutorialComplete: 1});
		this.closeModal();
	}

	public closeModal(): void {
		this.formSubmit.next(false);
	}

	public getBookingLink(): string {
		return 'https://' + UserService.KiCustomer + '.kibook.co.uk/';
	}

	public getFacebookLink(): SafeResourceUrl {
		const url = `https://www.facebook.com/plugins/share_button.php?href=${this.getBookingLink()}&layout=button&size=large&width=77&height=28&appId`;
		return this.domSantizer.bypassSecurityTrustResourceUrl(url);
	}

	public ngOnInit() {
		this.userService.getUser().then(userData => {
			this.userTableData.load(userData);
		});

		this.servicesService.getServices().then(serviceData => {
			this.serviceTableData.load((serviceData as any));
		});

		this.customerData = this.customerService.customer.value[0];
	}
}
