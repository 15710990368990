import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'ngx-year-calendar',
	template: `
		<div id='yearcalendar' class="{{ ((_loading) ? 'loading' : 'notloading') }}">
			<div class="header">
				<span *ngIf="options.showNavButtons" class="headerleft">
					<button type="button" class="btn btn-secondary" (click)="(year = year - 1)">{{(year - 1)}}</button>
				</span>

				<span class="headertitle">
					{{year}}
				</span>

				<span *ngIf="options.showNavButtons" class="headerright">
					<button type="button" class="btn btn-secondary" (click)="(year = year + 1)">{{(year + 1)}}</button>
				</span>
			</div>
			<div class="body">
				<div class="moy {{(mi+1)}}" *ngFor="let month of _data; let mi=index;">
					<h4> {{ month.name }} </h4>
					<div>

						<div class="dow {{di}}" *ngFor="let day of month.days; let di=index;">
							<b> {{month.days[di].name}} </b>
							<div class='dod' *ngFor="let date of day.dates">
								<a
									[attr.href] =  "((date.urls?.length > 0) ? date.urls[0] : '')"
									class = "dode {{ ( (date.classes?.length > 0) ? 'hasdata' : '') }} {{ ((options.colors.cssFallBack) ? date.classes?.join(' ') : '')}}"
								>
									{{ date.dateNum ? date.dateNum : '&nbsp;' }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="info">
				<h5>Key</h5>
				<div class='key' *ngFor="let classKey of _classesKey">
					<a class="{{classKey}}" href='javascript:void(0)'>&nbsp;&nbsp;&nbsp;</a> {{classKey}}
				</div>
			</div>
		</div>
	`,
	styles: [
		'div#yearcalendar.loading{ background-color: blue; }',
		'div#yearcalendar.notloading{ #background-color: green; }',
		'.header { width: 100%; margin: 0 auto; }',
		'span.headertitle{font-weight: 500; font-size:2.5rem;}',
		'span.headerleft { float: left; }',
		'span.headerright { float: right; }',
		'#yearcalendar {width: 95%; margin: 0 auto; text-align: center;}',
		'div.dow, div.moy { display: inline-block; text-align: center; }',
		'div.moy {font-size: 1vmax; padding: 5px; border-top: 1px solid;}',
		'div.dow {vertical-align: text-top;}',
		'a.dode {display: block; border-radius:2.5px; padding: 2.5px 5px}',
		'a.dode.hasdata{color: white}',
		'div.info {border-radius: 10px; border: 1px dotted;}',
		'div.info .key {display:inline-block; padding: 5px}',
	],
})
export class YearCalendarComponent implements OnInit, OnDestroy {

	// Internal var for building calendar
	public _data: any = [];
	public _classesKey: any = [];
	public _loading: boolean = false;

	// Set up Getter/Setter for events
	private _events: any;
	public get events(): any {
		return this._events;
	}
	@Input()
	public set events(value: any) {
		if (this._events !== value) {
			this._events = value;
			this.processDate(this.year);
		}
	}

	// Set up Getter/Setter for year
	private _year: number;
	public get year(): number {
		return (this._year) ? this._year :  (new Date().getFullYear());
	}
	@Input()
	public set year(value: number) {
		if (this._year !== value) {
			this._year = value;
			this.getEvents.emit(value);
		}
	}

	// Set up Getter/Setter for year
	private _defaultOptions: any = {
		showNavButtons: true,
		view: 'year',
		colors: {
			cssFallBack: true,
		},
	};
	private _options: any = {};
	public get options(): any {
		return {...this._defaultOptions, ...this._options};
	}
	@Input()
	public set options(value: any) {
		if (this._options !== value) {
			this._options = value;
		}
	}

	// Outputs
	@Output() getEvents: EventEmitter<any> = new EventEmitter();

	constructor(
		private domSanitizer: DomSanitizer,
	) {}

	// Initialize
	ngOnInit() {
		this.getEvents.emit(this.year);
	}

	// Process Date
	private processDate(year: number): any {

		// set loading
		this._loading = true;

		// Clear any existing data
		this._data = [];

		// Define date (first of this year), nextyear for range of dates to generate
		const date = new Date(year.toString());
		const nextYear = new Date((year + 1).toString());

		console.log('while ', date, ' < ', nextYear);

		while (date < nextYear) {

			// Iterate every day of the year

			// copy the date value rather than trying to manipulate directly
			const tmpDate = new Date(date.valueOf());

			// Generate some handy variables
			const MonthName = tmpDate.toLocaleString('en-us', { month: 'long' });
			const monthNum = tmpDate.getMonth();
			const dateNum = tmpDate.getDate();
			const dayName = tmpDate.toLocaleString('en-us', { weekday: 'short' })
			const dayNum = tmpDate.getDay();

			// Create month object if not exists
			if (!this._data[monthNum]) {

				// Sure hope this doesnt change
				let daysInWeek = 7;

				this._data[monthNum] = {
					name: MonthName,
					days: new Array(daysInWeek),
				};

				while (daysInWeek--) {
					this._data[monthNum].days[daysInWeek] = {
						name: '',
						dates: new Array(),
					}
				}

				// If the first of the month isnt a sunday, generate some placeholder (0)
				// This helps keep the calendar uniform
				if (dateNum === 1 && dayNum > 0) {
					let i = dayNum;
					while (i > 0) {
						i--;
						this._data[monthNum].days[i].dates.push('');
					}
				}
			}

			// Work out matching events
			const dateEvents = (this.events) ? this.events.filter(x => ((new Date(x.start.split(' ')[0])).toLocaleDateString() === (tmpDate.toLocaleDateString()) || (new Date(x.end.split(' ')[0])).toLocaleDateString() === (tmpDate.toLocaleDateString()))) : [];

			// push
			this._data[monthNum].days[dayNum].name = dayName;
			this._data[monthNum].days[dayNum].dates.push(
				{
					dateNum: dateNum,
					classes: this.getEventClasses(dateEvents),
					urls: this.getEventURLs(dateEvents),
				},
			);

			// Increment by a single day
			date.setDate(date.getDate() + 1);
		}

		this._loading = false;
	}

	private getEventClasses(dateEvents): any {

		const classes = [];
		if (dateEvents.length > 0) {
			dateEvents.forEach(dateEvent => {
				classes.push(dateEvent.className);

				// also store for key
				if (!this._classesKey.includes(dateEvent.className)) {
					this._classesKey.push(dateEvent.className);
				}
			});
		}

		// done
		return classes;
	}

	private getEventURLs(dateEvents): any {

		// this is tricky cos technically can have multiple urls on one day?
		// TODO: which one do we want?
		const urls = [];
		if (dateEvents.length > 0) {
			dateEvents.forEach(dateEvent => {
				urls.push(dateEvent.url);
			});
		}

		// last url is a sanitized one just incase
		urls.push(this.sanitizeUrl('javascript:void(0)'));

		// done
		return urls;
	}

	private sanitizeUrl(url: string) {
		return this.domSanitizer.bypassSecurityTrustUrl(url);
	}

	// Cleanup
	ngOnDestroy() {}
}
