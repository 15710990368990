import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import { ModalService } from './@core/data/globals/modal.service';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate> {

		constructor(private modalService: ModalService) {console.log('anyone???????')}

		canDeactivate(component: CanComponentDeactivate) {
				console.log('component', component);
				console.log('!component.canDeactivate', !component.canDeactivate);
				console.log('component.canDeactivate()', component.canDeactivate());

				// Allow navigation if the component says that it is OK or it doesn't have a canDeactivate function
				if (!component.canDeactivate || component.canDeactivate()) {
						return true;
				}

				return Observable.create((observer: Observer<boolean>) => {
						// UnsavedChangesDialog defined somewhere else and imported above
						this.modalService.newModal(
							'Unsaved changes',
							`You have unsaved changes. Are you sure you want to leave this page?
							<button (click)="callback('Proceed')"
								type="submit" class="btn btn-primary btn-block"> Proceed </button>
							<button (click)="callback('Cancel')"
								type="submit" class="btn btn-primary btn-danger"> Cancel </button>
							`,
							((response) => {
								switch (response) {
									case 'Proceed': {
										observer.next(true);
										observer.complete();
										break;
									}
									case 'Cancel': {
										observer.next(false);
										observer.complete();
										break;
									}
								};
							}), null, true,
						);
				});
		}
}
