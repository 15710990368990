
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const reportMutation = gql`
  mutation reportMutation(
			$reportId: Int, $reportName: String, $reportType: String, $reportQuery: String
		) {
		updateReport(
			reportId: $reportId, reportName: $reportName, reportType: $reportType, reportQuery: $reportQuery
		) {
			reportId,
			reportName,
			reportType
			reportQuery
		}
  	}
`

const reportsQuery = gql`
	query reportQuery($reportId: Int) {
		reports(reportId: $reportId)
		{
			reportId
			reportName
			reportType
			reportQuery
		}
	}
`

const runReportQuery = gql`
	query reportQuery($reportId: Int) {
		reports(reportId: $reportId)
		{
			reportId
			reportResults
		}
	}
`

const getReportDefinitionsQuery = gql`
	query reportQuery($reportId: Int) {
		reports(reportId: $reportId)
		{
			reportId
			reportDefinitions
		}
	}
`

@Injectable()
export class ReportService implements OnDestroy {

	public loading = true;
	public reports = new BehaviorSubject<any>(null);
	private sub: Subscription;

	private lastVars = {}

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.getReports();
	}

	public getReport(reportId, run: boolean = false, definitionsOnly = false): Promise<any> {

		return new Promise((resolve, reject) => {

			this.lastVars = {
				reportId: reportId,
			}

			this.sub = this.apollo.watchQuery({
				query: definitionsOnly ? getReportDefinitionsQuery : (run ? runReportQuery : reportsQuery),
				variables: this.lastVars,
				fetchPolicy: 'network-only',
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				resolve(data['reports'][0]);
			}, (error) => {
				console.log(error);
				reject(error)
				this.toastr.warning('There was an error retrieving data', error);
			});

		})
	}

	public getReports(): Promise<any> {

		return new Promise((resolve, reject) => {

			this.sub = this.apollo.watchQuery({
				query: reportsQuery,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.reports.next(JSON.parse(JSON.stringify(data['reports'])));
				resolve(this.reports.value);
			}, (error) => {
				console.log(error);
				reject(error)
				this.toastr.warning('There was an error retrieving data', error);
			});
		})
	}

	public updateReport(report: any): Promise<any> {

		console.log('updateReport', report);

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: reportMutation,
				variables: {
					reportId: report.reportId,
					reportName: report.reportName,
					reportType: report.reportType,
					reportQuery: JSON.stringify(report.reportQuery),
				},
				refetchQueries: [{
					query: reportsQuery,
					variables: this.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
