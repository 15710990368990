
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { ModalService } from '../globals/modal.service';
import { CategoriesService } from './categories.service';
import * as humanizeDuration from 'humanize-duration';

const serviceMutation = gql`
  mutation updateService(
			$serviceId: Int, $serviceEnabled: String, $serviceName: String, $servicePrice: String,
			$serviceDeposit: String, $serviceLength: String, $serviceDescription: String, $serviceNotes: String, $serviceDOW: String,
			$serviceActiveto: String, $serviceActivefrom: String, $serviceSortOrder: Int, $serviceCategory: Int,
			$serviceIsAddon: Int, $serviceAddons: String, $serviceAttendance: Int, $serviceColor: String, $isDeleted: Int
		) {
			updateService(
				serviceId: $serviceId, serviceEnabled: $serviceEnabled, serviceName: $serviceName,
				servicePrice: $servicePrice, serviceDeposit: $serviceDeposit, serviceLength: $serviceLength,
				serviceDescription: $serviceDescription, serviceNotes: $serviceNotes, serviceDOW: $serviceDOW, serviceActiveto: $serviceActiveto,
				serviceActivefrom: $serviceActivefrom, serviceSortOrder: $serviceSortOrder, serviceCategory: $serviceCategory,
				serviceIsAddon: $serviceIsAddon, serviceAddons: $serviceAddons, serviceAttendance: $serviceAttendance, serviceColor: $serviceColor, meta_isDeleted: $isDeleted
			) {
				serviceId
				serviceEnabled
				serviceName
				servicePrice
				serviceDeposit
				serviceLength
				serviceDescription
				serviceNotes
				serviceDOW
				serviceActivefrom
				serviceActiveto
				serviceCategory
				serviceIsAddon
				serviceAddons {
					serviceId
					serviceEnabled
					serviceAddonEnabled
					serviceName
					servicePrice
					serviceDeposit
					serviceLength
					serviceDescription
					serviceActivefrom
					serviceActiveto
				}
				serviceUsers {
					userId
					userUsername
				}
				serviceSortorder
				serviceAttendance
				serviceColor
			}
  		}
`

@Injectable()
export class ServicesService implements OnDestroy {

	public loading = true;
	public services = new BehaviorSubject<any>(null);
	private sub: Subscription;

	public static servicesQuery = gql`
	query services(
		$serviceId: [Int]
	)
	{
		services(serviceId: $serviceId) {
			serviceId
			serviceEnabled
			serviceName
			servicePrice
			serviceDeposit
			serviceLength
			serviceDescription
			serviceNotes
			serviceDOW
			serviceActivefrom
			serviceActiveto
			serviceCategory
			serviceIsAddon
			serviceAddons {
				serviceId
				serviceEnabled
				serviceAddonEnabled
				serviceAddonSelected
				serviceName
				servicePrice
				serviceDeposit
				serviceLength
				serviceDescription
				serviceActivefrom
				serviceActiveto
			}
			serviceUsers {
				userId
				userUsername
			}
			serviceSortorder
			serviceAttendance
			serviceColor
		}
	}
`
	public static lastVars = {
		serviceId: null,
	}

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
		private modalService: ModalService,
	) {}

	public getServices(serviceIds: number[] = null) {

		ServicesService.lastVars.serviceId = (serviceIds) ? (serviceIds).join(',') : null;

		return new Promise((resolve, reject) => {

			this.sub = this.apollo.watchQuery({
				query: ServicesService.servicesQuery,
				variables: ServicesService.lastVars,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.services.next(JSON.parse(JSON.stringify(data['services'])));
				resolve(this.services.value);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public getService(serviceId: number) {
		if (!this.services.value) {
			this.getServices().then(x => {
				this.getService(serviceId);
			});
		} else {
			return this.services.value.filter(service => Number(service.serviceId) === Number(serviceId))[0];
		}
	}

	public serviceLengthToString(serviceLengthInMinutes) {
		return humanizeDuration(serviceLengthInMinutes * 60000, { units: ['h', 'm'] });
	}

	public updateService(service: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: serviceMutation,
				variables: {
					serviceId: service.serviceId,
					serviceEnabled: service.serviceEnabled ? 1 : 0,
					serviceName: service.serviceName,
					servicePrice: service.servicePrice,
					serviceDeposit: service.serviceDeposit,
					serviceLength: service.serviceLength,
					serviceDescription: service.serviceDescription,
					serviceNotes: service.serviceNotes,
					serviceDOW: service.serviceDOW,
					serviceActiveto: service.serviceActiveto,
					serviceActivefrom: service.serviceActivefrom,
					serviceSortOrder: service.serviceSortorder,
					serviceCategory: service.serviceCategory,
					serviceIsAddon: service.serviceIsAddon ? 1 : 0,
					serviceAddons: service.serviceAddons ? service.serviceAddons.filter(svc => svc.serviceAddonEnabled).map(svc => svc.serviceId).join() : '0',
					serviceAttendance: service.serviceAttendance,
					serviceColor: service.serviceColor,
					isDeleted: service.isDeleted ? 1 : 0,
				},
				refetchQueries: [{
					query: ServicesService.servicesQuery,
					variables: ServicesService.lastVars,
				}, {
					query: CategoriesService.categoryQuery,
					variables: CategoriesService.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public customServiceForm() {

		// So create custom
		const service = {
			serviceId: -1,
			serviceName: '',
			servicePrice: '',
			serviceDeposit: '',
			serviceLength: '',
		}

		// TODO CONVERT TO COMPONENT?

		return new Promise((resolve, reject) => {
			const modal = this.modalService.newModal(
				'Custom Service',
				`
				<form #tForm="ngForm" (ngSubmit)="callback(tForm, null, $event)" ngNativeValidate>

					<div class="input-group mail-btn-group">
						<span class="input-group-prepend">
							<button class="btn btn-primary btn-icon input-group-text" tabindex="-1">
								<i class="fa fa-file-text"></i> Name
							</button>
						</span>
						<input
							[(ngModel)]="contextRef.serviceName"
							placeholder="Custom Service Name"
							type="text"
							class="form-control"
							name="name"
						required>
					</div>

					<div class="input-group mail-btn-group">
						<span class="input-group-prepend">
							<button class="btn btn-primary btn-icon input-group-text" tabindex="-1">
								<i class="fa fa-money"></i> Price
							</button>
						</span>
						<input
							[(ngModel)]="contextRef.servicePrice"
							placeholder="Total cost of appointment"
							type="number" step="0.01" min="0"
							class="form-control"
							name="number"
						required>
					</div>

					<div class="input-group mail-btn-group">
						<span class="input-group-prepend">
							<button class="btn btn-primary btn-icon input-group-text" tabindex="-1">
								<i class="fa fa-credit-card"></i> Deposit
							</button>
						</span>
						<input
							[(ngModel)]="contextRef.serviceDeposit"
							placeholder="Deposit Client should pay"
							type="number" step="0.01" min="0"
							class="form-control"
							name="number"
						required>
					</div>

					<div class="input-group mail-btn-group">
						<span class="input-group-prepend">
							<button class="btn btn-primary btn-icon input-group-text" tabindex="-1">
								<i class="fa fa-clock-o"></i> Length
							</button>
						</span>
						<input
							[(ngModel)]="contextRef.serviceLength"
							placeholder="Appointment length (in Minutes)"
							type="number"
							class="form-control"
							name="length"
						required>
					</div>

					<button type="submit" class="btn btn-primary">
						<i class="fa fa-floppy-o"></i> Save
					</button>
				</form>
				`,
				(element, args) => {

					resolve(service);
					modal.close();
				},
				service,
			);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
