import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { DatePipe } from '@angular/common';

const leaveQuery = gql`
query leaveQuery(
	$leaveId: Int,
	$leaveUser: Int
)
{
	leave(leaveId: $leaveId, leaveUser: $leaveUser)
	{
		leaveId
		leaveFrom
		leaveTo
		leaveNotes
		leaveUser
		leaveUserUsername
	}
}
`

const leaveMutation = gql`
mutation leaveMutation(
	$leaveId: Int,
	$leaveFrom: String,
	$leaveTo: String,
	$leaveNotes: String,
	$leaveUser: Int,
	$isDeleted: Int
) {
	updateLeave(
		leaveId: $leaveId,
		leaveFrom: $leaveFrom,
		leaveTo: $leaveTo,
		leaveNotes: $leaveNotes,
		leaveUser: $leaveUser,
		meta_isDeleted: $isDeleted
	) {
		leaveId
		asEvent {
			eventId
			start
			end
			title
			className
			url
			resourceId
			resourceName
		}
	}
}
`

const appointmentMutation = gql`
mutation appointmentMutation(
	$eventId: Int,
	$start: String,
	$end: String,
	$type: Int,
	$client: Int,
	$title: String,
	$notes: String,
	$clientNotes: String,
	$cancelled: Int,
	$checkedOut: Int,
	$confirmed: Int,
	$price: String,
	$order: Int,
	$deposit: String,
	$length: Int,
	$resourceId: Int,
	$autoDeposit: Int,
	$isDeleted: Int
) {
	updateAppointment(
		apptId: $eventId,
		apptStart: $start,
		apptEnd: $end,
		apptType: $type,
		apptClient: $client,
		apptTitle: $title,
		apptOwnNotes: $notes,
		apptClientNotes: $clientNotes,
		apptCancelled: $cancelled,
		apptCheckedout: $checkedOut,
		apptConfirmed: $confirmed,
		apptPrice: $price,
		apptOrder: $order,
		apptDeposit: $deposit,
		apptLength: $length,
		apptUser: $resourceId,
		autoDeposit: $autoDeposit,
		meta_isDeleted: $isDeleted,
	) {
		apptId
		apptTitle
		apptStart
		apptEnd
		apptType
		apptAddons {
			serviceName
			servicePrice
			serviceLength
		}
		apptClient
		apptClientName
		apptClientNotes
		apptOwnNotes
		apptCancelled
		apptConfirmed
		apptCheckedout
		apptStatus
		apptPrice
		apptPaid
		apptOwed
		apptDeposit
		apptOrder
		apptOrderReceiptHash
		apptUser
		apptUrl
	}
}
`

@Injectable()
export class AvailabilityService implements OnDestroy {

	public loading = true;
	public availability = new BehaviorSubject<any>([]);
	public leave = new BehaviorSubject<any>([]);
	private sub: Subscription = new Subscription(() => {});
	private leaveSub: Subscription = new Subscription(() => {});

	//
	static lastvars: any = [];
	static availabilityQuery = gql`
		query availabilityQuery(
			$start: String,
			$end: String,
			$config: String,
			$user: Int,
		)
		{
			availability(start: $start, end: $end, config: $config, user: $user)
			{
				eventId
				start
				end
				title
				className
				color
				url
				resourceId
			}
		}
	`
	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
		private datePipe: DatePipe,
	) { }

	public getAvailability(startDate, endDate, configName = '', user = null): Promise<any> {

		return new Promise((resolve, reject) => {

			startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
			endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
			AvailabilityService.lastvars = {
				start: startDate,
				end: endDate,
				config: configName,
				user: user,
			}

			this.sub = this.apollo.watchQuery({
				query: AvailabilityService.availabilityQuery,
				variables: AvailabilityService.lastvars,
				fetchPolicy: 'network-only',
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				if (data['availability']) {
					this.availability.next(JSON.parse(JSON.stringify(data['availability'])));
					console.log('availability', this.availability)
					resolve(this.availability);
				}
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public forceRefetch() {
		this.getAvailability(AvailabilityService.lastvars.startDate, AvailabilityService.lastvars.endDate, AvailabilityService.lastvars.config);
	}

	public getLeave(leaveId: Number): Promise<any> {

		return new Promise((resolve, reject) => {

			this.leaveSub = this.apollo.watchQuery({
				query: leaveQuery,
				variables: {
					leaveId: leaveId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				if (data['leave']) {
					this.leave.next(JSON.parse(JSON.stringify(data['leave'])));
					resolve(this.leave.value);
				}
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				resolve(this.leave.value);
			});

		});
	}

	public updateAppointment(apptObj): any {
		console.log('updateAppointment', apptObj)

		const apptVariables = {
			eventId: (apptObj.start ? apptObj.eventId : apptObj.apptId),
			start: (apptObj.start ? apptObj.start : apptObj.apptStart),
			end: (apptObj.start ? apptObj.end : apptObj.apptEnd),
			type: (apptObj.start ? apptObj.type : apptObj.apptType),
			client: (apptObj.start ? apptObj.client : apptObj.apptClient),
			title: (apptObj.start ? apptObj.title : apptObj.apptTitle),
			notes: (apptObj.start ? apptObj.notes : apptObj.apptOwnNotes),
			clientNotes: (apptObj.start ? apptObj.clientNotes : apptObj.apptClientNotes),
			cancelled: (apptObj.start ? apptObj.cancelled : apptObj.apptCancelled),
			checkedOut: (apptObj.start ? apptObj.checkedOut : apptObj.apptCheckedout),
			confirmed: (apptObj.start ? apptObj.confirmed : apptObj.apptConfirmed),
			price: (apptObj.start ? apptObj.price : apptObj.apptPrice),
			order: (apptObj.start ? apptObj.order : apptObj.apptOrder),
			deposit: (apptObj.start ? apptObj.deposit : apptObj.apptDeposit),
			resourceId: (apptObj.start ? apptObj.resourceId : apptObj.apptUser),
			autoDeposit: (apptObj.start ? apptObj.autoDeposit : apptObj.autoDeposit),
			isDeleted: (apptObj.start ? apptObj.isDeleted : apptObj.isDeleted),
		};

		return new Promise((resolve, reject) => {

			if (new Date(apptVariables.start) >= new Date(apptVariables.end)) {
				this.toastr.error('Start date/time must be before end date/time');
				reject('Invalid date/time');
				return;
			}

			this.apollo.mutate({
				mutation: appointmentMutation,
				variables: apptVariables,
				refetchQueries: [{
					query: AvailabilityService.availabilityQuery,
					variables: AvailabilityService.lastvars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data['updateAppointment']);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});

		});
	}

	public toDateTimeLocal(inputDate?: any, inputString: string = 'now'): string {

		console.log('toDateTimeLocal', inputDate, inputString)
		let result = null;
		if (!inputDate) {
			inputString = inputString.replace(/-/g, '/');
			inputDate = new Date(inputString);
			result = (inputDate.toISOString().split('T')[0]) + 'T' + ('0' + (inputDate.getHours())).slice(-2) + ':' + ('0' + inputDate.getMinutes()).slice(-2) + ':00';
		} else {
			result = (inputDate.toISOString().split('T')[0]) + 'T' + ('0' + (inputDate.getHours())).slice(-2) + ':' + ('0' + inputDate.getMinutes()).slice(-2) + ':00';
		}
		console.log('toDateTimeLocal(' + inputDate + ', ' + inputString + ')', result);
		return result;
	}

	public updateLeave(leaveObj) {

		return new Promise((resolve, reject) => {

			// todo
			// we're sending fc-events here directly sometimes
			// hence this mess
			const leaveVariables = {
				leaveId: (leaveObj.eventId ? leaveObj.eventId : leaveObj.leaveId),
				leaveFrom: (leaveObj.start ? leaveObj.start : leaveObj.leaveFrom),
				leaveTo: (leaveObj.end ? leaveObj.end : leaveObj.leaveTo),
				leaveNotes: (leaveObj.title ? leaveObj.title : leaveObj.leaveNotes),
				leaveUser: (leaveObj.resourceId ? leaveObj.resourceId : leaveObj.leaveUser),
				isDeleted: (leaveObj.isDeleted ? leaveObj.isDeleted : false),
			}

			if (new Date(leaveVariables.leaveFrom) >= new Date(leaveVariables.leaveTo)) {
				this.toastr.error('Start date/time must be before end date/time');
				reject('Invalid date/time');
				return;
			}

			console.log('updateLeave', leaveObj)

			this.apollo.mutate({
				mutation: leaveMutation,
				variables: leaveVariables,
				refetchQueries: [{
					query: AvailabilityService.availabilityQuery,
					variables: AvailabilityService.lastvars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); }
		if (this.leaveSub) { this.leaveSub.unsubscribe(); }
	}
}
