
import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';


const mailerQuery = gql`
	query mailer($emailTo: Int, $emailType: String, $emailOrderId: Int) {
		mailer(emailTo: $emailTo, emailType: $emailType, emailOrderId: $emailOrderId) {
			name
			data
		}
	}
`

@Injectable()
export class MailerService implements OnDestroy {

	public loading = true;
	private sub: Subscription;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public sendEmail(emailTo: Number, emailType: String, emailOrderId: String ): any {

		if (!emailTo) {
			this.toastr.error('sendEmail: no client provided');
			return;
		}
		if (!emailType) {
			this.toastr.error('sendEmail: no type provided');
			return;
		}

		this.sub = this.apollo.watchQuery({
			query: mailerQuery,
			variables: {
				emailTo: emailTo,
				emailType: emailType,
				emailOrderId: emailOrderId,
			},
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				const payload = JSON.parse(JSON.stringify(data['mailer']));
				payload.data = JSON.parse(payload.data);
				this.toastr.success('Email Successful');
				return payload;
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
