import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { ClientService } from '../../../@core/data/clients/client.service';

@Component({
	selector: 'ngx-clientadd',
	styleUrls: ['./clientadd.component.scss'],
	templateUrl: './clientadd.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class ClientAddComponent implements OnInit {

	public client = {
		clientName: '',
		clientNumber: '',
		clientEmail: '',
		clientNotes: '',
		clientAddress: '',
	}

	@Output() formSubmit: EventEmitter<any> = new EventEmitter(false);
	@Input() showClose: boolean = false;

	constructor(
		private clientService: ClientService,
	) {}

	public ngOnInit(): void {

	}

	public save(): void {
		this.clientService.updateClient(this.client).then(newClient => {
			this.formSubmit.next(newClient);
		});
	}

	public closeModal(): void {
		this.formSubmit.next(this.client);
	}
}
