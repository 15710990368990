import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailabilityService } from '../../../@core/data/availability/availability.service';
import { UserService } from '../../../@core/data/users/user.service';

@Component({
	selector: 'ngx-leaveview',
	styleUrls: ['./leaveview.component.scss'],
	templateUrl: './leaveview.component.html',
	host: {
		'[style.display]': '"flex"',
		'[style.flex-direction]': '"column"',
		'[style.overflow]': '"hidden"',
	},
})
export class LeaveViewComponent implements OnInit, OnDestroy {

		@Input()
		public leave: any = {};

		@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
		@Input() showClose: boolean = false;

		public leaveId;
		public loading = true;
		public dataSub: Subscription;

		public userList;

		constructor(
			private route: ActivatedRoute,
			private router: Router,
			private availabilityService: AvailabilityService,
			private userService: UserService,
		) {}

		public ngOnInit(): void {

			this.dataSub = this.route.params.subscribe(params => {
				this.leaveId = (params.id) ? +params['id'] : this.leaveId;

				if (this.availabilityService.leave.value && this.availabilityService.leave.value.filter(x => (Number(x.leaveId) === Number(this.leaveId))).length === 1) {
					this.leave = this.availabilityService.leave.value.filter(x => (Number(x.leaveId) === Number(this.leaveId)))[0];
				} else {
					if (this.leaveId) {
						console.log('getLeave: ', this.leaveId);
						this.availabilityService.getLeave(this.leaveId).then(leaveResult => {
							console.log('leaveResult', leaveResult);
							this.leave = leaveResult[0];
						});
					}
				}
			});

			this.userService.getUserList().then(userList => {
				this.userList = userList;
			})
		}

		public save(): void {

			if (typeof this.leave.leaveTo === 'object') {
				this.leave.leaveTo = this.availabilityService.toDateTimeLocal(this.leave.leaveTo);
			}

			if (typeof this.leave.leaveFrom === 'object') {
				this.leave.leaveFrom = this.availabilityService.toDateTimeLocal(this.leave.leaveFrom);
			}

			this.availabilityService.updateLeave(this.leave);
			this.closeModal();
		}

		public closeModal(): void {
			this.formSubmit.next(false);
		}

		public delete(): void {
			if (confirm('Are you sure?')) {
				this.leave.isDeleted = true;
				this.availabilityService.updateLeave(this.leave).then(updatedLeave => {
					console.log('deleted', updatedLeave);
					this.router.navigateByUrl('/pages/appointments');
				});
			}
		}

		public ngOnDestroy(): void {
			if (this.dataSub) { this.dataSub.unsubscribe(); };
		}
}
