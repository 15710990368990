import {Component, Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

	@Component({
	selector: 'ngx-fab',
	styleUrls: ['./floatingActionBar.component.scss'],
	template: `
	  <nav class="fab-menu" [class.active]="active" [class.inactive]="!active">
		<a class="fab-item fab-button" *ngFor="let button of buttons" [class.hasText]="button.text"
			[attr.href]="(button.link) ? button.link : sanitizeUrl('javascript:void(0)')"
			(click)="(button.action) ? button.action() : null"
			>

			<i class="fa {{button.icon}}" aria-hidden="true"></i>
			<span *ngIf="button.text">{{button.text}}</span>
		</a>
		<a class="fab-item fab-toggle" (click)="toggleFab()">
		  	<i class="fa fa-plus" aria-hidden="true"></i>
		</a>
	  </nav>
	`,
	})
	export class FloatingActionBarComponent {

		@Input() buttons: any = null;
		public active: boolean;

		constructor(private domSanitizer: DomSanitizer) {}

		public toggleFab(): void {
			this.active = !this.active;
		}

		public sanitizeUrl(url: string) {
			return this.domSanitizer.bypassSecurityTrustUrl(url);
		}

 }
