
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { AvailabilityService } from './availability.service';

const scheduleMutation = gql`
  mutation updateSchedule(
			$scheduleShifts: String, $scheduleFrom: String, $scheduleTo: String, $scheduleDay: String!, $scheduleUser: Int!
		) {
			updateSchedule(
				scheduleShifts: $scheduleShifts,
				scheduleFrom: $scheduleFrom,
				scheduleTo: $scheduleTo,
				scheduleDay: $scheduleDay,
				scheduleUser: $scheduleUser
			){
				dayName
				dayNum
				events
				{
				  title
				  className
				  resourceId
				}
			}
		}
`

@Injectable()
export class ScheduleService implements OnDestroy {

	public loading = true;
	public schedule = new BehaviorSubject<any>(null);
	private sub: Subscription = new Subscription(null);

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public getUserSchedule() {

		const query = gql`
		{
			schedule
			{
			  dayName
			  dayNum
			  events
			  {
				title
				className
				resourceId
			  }
			}
		}
		`

		this.sub = this.apollo.watchQuery({
			query: query,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data['schedule']) {
				this.schedule.next(JSON.parse(JSON.stringify(data['schedule'])));
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	updateSchedule(scheduleObj: any, resourceId: number = null): void {

		// filter slots
		const slotArray = [];
		let validationFailReason = null;
		console.log('scheduleObj', scheduleObj)

		// filter slots
		// return only valid available events from scheduleObj.scheduleFrom/scheduleTo
		scheduleObj.events.filter(x => {

			validationFailReason = null;
			let flagError = false;
			if (!(resourceId == null) && !(Number(x.resourceId) === Number(resourceId))) {

				// null or mismatching resourceId
				return false;
				// validationFailReason = 'null or mismatching resourceId';

			} else if (x.className !== 'fc-available' && !x.className.includes('fc-available')) { // Filter by available

				// classname is not fc-available
				// return false;
				validationFailReason = 'classname is not fc-available';

			} else if (
				// contains a date (Y-m-d H:i:s)
				(x.start.includes(' '))
				// and not current date
				&& !(x.start.split(' ')[0] === scheduleObj.scheduleFrom && x.end.split(' ')[0] === scheduleObj.scheduleTo)
				) {

				// not on same day/date
				// return false;
				validationFailReason = 'not on same day/date: ' + (x.start.split(' ')[0]) + ' - ' + scheduleObj.scheduleFrom;

			} else if (!(
					// date is not null
					x.start !== null
					// end date is not
					&& x.end !== null
					// start is before end check
					// cast to date if contains a year, otherwise just use the time
					&& (x.start.includes(' ') && (new Date(x.start) < new Date(x.end)) || x.start < x.end)
				)) {
				validationFailReason = ('event start date (' + (x.start) + ') is after event end date (' + (x.end) + ')');
				flagError = true;
			}

			if (validationFailReason !== null) {

				if (flagError) {
					this.toastr.warning('There was an error saving changes', validationFailReason);
				}
				console.log('updateSchedule FAIL (' +  x.start + '/' + x.className + '):', validationFailReason);
				return false;
			}

			return true;
		}).forEach(x => {
			const start = x.start.match(/([0-9]+:)\w+/g);
			const end = x.end.match(/([0-9]+:)\w+/g);
			console.log(`adding: ${start} ${end}`)
			slotArray.push(`${start} ${end}`);
		});

		if (!validationFailReason) {

			// Build schedule object
			scheduleObj.slots = (slotArray.length > 0) ? slotArray.join(', ') : '';

			this.saveSchedule(scheduleObj, resourceId);
		}
	}

	public saveSchedule(scheduleObj: any, resourceId: number = null): void {

		console.log('saveSchedule->scheduleObj', scheduleObj);
		console.log('saveSchedule->resourceId', resourceId);
		this.apollo.mutate({
			mutation: scheduleMutation,
			variables: {
				scheduleShifts: scheduleObj.slots,
				scheduleDay: scheduleObj.dayName,
				scheduleTo: scheduleObj.scheduleTo,
				scheduleFrom: scheduleObj.scheduleFrom,
				scheduleUser: resourceId,
			},
			refetchQueries: [{
				query: AvailabilityService.availabilityQuery,
				variables: AvailabilityService.lastvars,
			}],
		}).subscribe(({ data }) => {
			console.log('got data', data);
			this.toastr.success('Changes Saved');
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error saving changes', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); }
	}
}
