import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { VouchersService } from '../../../@core/data/store/vouchers.service';

@Component({
	selector: 'ngx-voucheradd',
	styleUrls: ['./voucheradd.component.scss'],
	templateUrl: './voucheradd.component.html',
})
export class VouchersAddComponent implements OnInit, OnDestroy {

		public voucher: any = {};
        @Output() formSubmit: EventEmitter<any> = new EventEmitter(false);
        @Input() showClose: boolean = false;

		constructor(
			private voucherService: VouchersService,
		) {}

		public ngOnInit(): void {

		}

        public closeModal(): void {
            this.formSubmit.next(false);
        }

		public newVoucher(voucher: any) {
            this.voucherService.updateVoucher(voucher).then(newVoucher => {
                this.formSubmit.next(newVoucher);
            });
        }

		public ngOnDestroy(): void {
		
        }
}
