
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class AppointmentsService implements OnDestroy {

	public loading = true;
	public appointments = new BehaviorSubject<any>([]);
	private sub: Subscription = new Subscription(() => {});

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
		private datePipe: DatePipe,
	) {}

	public getAppointments(startDate, endDate) {
		startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
		endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');

		const query = gql`
    {
      appointments(start: "${startDate}", end: "${endDate}")
      {
        apptId
        apptTitle
        apptStart
        apptEnd
        apptUser
        apptUrl
      }
    }
    `

		this.sub = this.apollo.watchQuery({
			query: query,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data['appointments']) {
				this.appointments = this.appointments;
				this.appointments.next(JSON.parse(JSON.stringify(data['appointments'])));
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

}
