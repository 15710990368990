
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationsService } from 'ng-push';

const query = gql`
query alerts($alertId: Int, $offset: Int, $count: Int) {
    alerts(alertId: $alertId, offset: $offset, count: $count) {
        alertId
        alertLevel
        alertTitle
		alertBody
		alertLink
		alertUnread
    }
}
`

const unreadTotalQuery = gql`
query alerts($alertId: Int) {
    alerts(alertId: $alertId) {
		alertUnreadTotal
    }
}
`
const mutation = gql`
mutation updateAlerts($alertId: Int, $alertLevel: Int, $alertTitle: String, $alertBody: String, $alertUnread: Int) {
    updateAlerts(alertId: $alertId, alertLevel: $alertLevel, alertTitle: $alertTitle, alertBody: $alertBody, alertUnread: $alertUnread) {
        alertId
        alertLevel
        alertTitle
		alertBody
		alertUnread
    }
}
`

@Injectable()
export class AlertService implements OnDestroy {

	public loading = true;
	public alerts = new BehaviorSubject<any>(null);
	public alertsUnreadTotal = new BehaviorSubject<number>(0);
	private sub: Subscription;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
		private pushNotifications: PushNotificationsService,
	) {
		this.pollAlerts(10);
	}

	public pollAlerts(count: number) {
		this.sub = this.apollo.watchQuery({
			query: query,
			variables: {
				count: count,
			},
			pollInterval: 10000,
			fetchPolicy: 'network-only',
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (this.alerts.value !== data['alerts']) {
				this.alerts.next(data['alerts']);
				this.getUnreadCount(true);
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public getUnreadCount(notify = false) {
		return new Promise((resolve, reject) => {
			this.apollo.query({query: unreadTotalQuery, fetchPolicy: 'network-only', variables: {alertId: 1}}).subscribe(value => {
				this.alertsUnreadTotal.next(value['data']['alerts'][0] ? value['data']['alerts'][0]['alertUnreadTotal'] : 0);
				if (notify && this.alertsUnreadTotal.value > 0) {
					this.notify('Unread Alerts', {
						body: `You have '${this.alertsUnreadTotal.value}' alerts.`,
					});
				}
				resolve(this.alertsUnreadTotal);
			}, (error) => {
				reject(error);
			});
		});
	}

	public notify(title: string, options?: any) {
		if (this.pushNotifications.permission === 'granted') {
			console.log('requesting notification!')

			this.pushNotifications.create(title, options)
				.subscribe(res => {
					console.log('res', res);
					if (res.event.type === 'click') {
						// You can do anything else here
						res.notification.close();
					}
				},
				err => console.log('err', err),
			);
		} else {
			console.log('requesting permission!')
			this.pushNotifications.requestPermission();
		}
	}

	public getAlerts(alertIds = null, offset: number = 0, count: number = 10): Promise<any> {

		return new Promise((resolve, reject) => {
			alertIds = (alertIds) ? (alertIds).join(',') : null;

			this.sub = this.apollo.watchQuery({
				query: query,
				variables: {
					alertId: alertIds,
					offset: offset,
					count: count,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				resolve(data['alerts']);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public updateAlert(alert: any): Promise<any> {

		return new Promise((resolve, reject) => {

			this.apollo.mutate({
				mutation: mutation,
				variables: {
					alertId: alert.alertId,
					alertLevel: alert.alertLevel,
					alertTitle: alert.alertTitle,
					alertBody: alert.alertBody,
					alertUnread: alert.alertUnread,
				},
			}).subscribe(({ data }) => {
				console.log('got data', data);
				// this.toastr.success('Changes Saved');
				this.getUnreadCount();
				resolve(data);
			}, (error) => {
				console.log(error);
				// this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});

		});

	}

	public markAsRead(alertId): Promise<any> {
		return this.updateAlert({alertId: alertId, alertUnread: 0});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
