import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScheduleService } from '../../../../@core/data/availability/schedule.service';
import { UserService } from '../../../../@core/data/users/user.service';

@Component({
	selector: 'ngx-schedule',
	templateUrl: './schedule.component.html',
})
export class ScheduleComponent implements OnInit, OnDestroy {

		public dataSub: Subscription;

		public data: any = [];
		public resources: any = [];

		constructor(
				private scheduleService: ScheduleService,
				private userService: UserService,
		) {}

		public filterResource(events, resId): any {
			return events.filter(z => z.resourceId === resId)
		}

		public ngOnInit(): void {
			this.dataSub = this.scheduleService.schedule.subscribe(value => {
				(value) ? this.data = value : null;
			});
			console.log(this.scheduleService);

			// Subscribe to user service changes
			this.userService.getUserList().then(value => {

				// Verify result
				if (value != null) {
					// Get resources where userServices field isn't empty (ie, they offer a service on the calendar)
					this.resources = [];
					console.log(value);

					// todo refactor cos wtf haha ...unless
					let first: boolean = true;
					Object.keys(value).forEach( (key) => {
						const resource = {
							title: value[key].userUsername,
							id: value[key].userId,
							active: (first) ? true : false,
							hasServices: value[key].userServices.trim().length > 0,
						}
						this.resources.push(resource)
						first = false;
					});
					console.log(this.resources);
				}
			});

			this.scheduleService.getUserSchedule();
		}

		public ngOnDestroy(): void {
			if (this.dataSub) { this.dataSub.unsubscribe(); };
		}

		public toggleNewMode(dayRef, dayName, resourceId, jsEvent): void {
			if (jsEvent) {
				jsEvent.preventDefault();
			}

			if (dayRef.newEvent) {
				dayRef.newEvent = false;
			} else {
				const dateRef = new Date;
				dayRef.newEvent = this.toggleEditMode(
					{
						start: `${dateRef.getHours()}:00`,
						end: `${(dateRef.getHours() + 1)}:00`,
						title: `${dateRef.getHours()}:00 ${(dateRef.getHours() + 1)}:00`,
						className: 'fc-available',
						editMode: false,
						resourceId: resourceId,
					},
				);
			}
		}

		public toggleEditMode(eventRef, jsEvent = null): void {
			if (jsEvent) {
				jsEvent.preventDefault();
			}

			// set event times
			eventRef.start =  (eventRef) ? eventRef.title.split(' ')[0] : `${(new Date).getHours()}:00`,
			eventRef.end = (eventRef) ? eventRef.title.split(' ')[1] : `${((new Date).getHours() + 1)}:00`,

			// Set event title
			eventRef.title = `${eventRef.start} ${eventRef.end}`;

			// available
			eventRef.className = 'fc-available';

			// mark as editMode
			eventRef.editMode = !eventRef.editMode;

			return eventRef;
		}

		public addEvent(event: any, dayNum, resourceId) {

			// Get specific day
			const scheduleDay =  this.data[dayNum - 1];

			// update title
			event.title = `${event.start} ${event.end}`;
			event.resourceId = resourceId;
			event = this.toggleEditMode(event);

			// add to specific day
			scheduleDay.events.push(event);
			scheduleDay.newEvent = false

			// Save
			this.scheduleService.saveSchedule(this.flattenSlots(scheduleDay, resourceId), resourceId);
		}


		public updateEvent(event, dayNum, resourceId) {

			// Get specific day
			const scheduleDay =  this.data[dayNum - 1];

			// Find existing object in data
			const index = scheduleDay.events.indexOf(scheduleDay.events.find(evt => evt.title === event.title && evt.resourceId === resourceId));

			if (index > -1) {

				// update title
				event.title = `${event.start} ${event.end}`;
				event = this.toggleEditMode(event);

				// Replace existing object by index
				scheduleDay.events[index] = event;

				// Save
				this.scheduleService.saveSchedule(this.flattenSlots(scheduleDay, resourceId), resourceId);
			} else {
				window.alert('Unable to locate event index');
				console.log('scheduleDay.events', scheduleDay.events);
				console.log('event', event);
			}
		}

		public removeEvent(event, dayNum, resourceId) {

			// Get specific day
			const scheduleDay =  this.data[dayNum - 1];

			// Find existing object in data
			const index = scheduleDay.events.indexOf(scheduleDay.events.find(evt => evt.title === event.title && evt.resourceId === resourceId));

			if (index > -1) {

				console.log('splicing at index', JSON.parse(JSON.stringify(scheduleDay.events)), index);
				// Remove by existing object by index
				scheduleDay.events.splice(index, 1);

				// Save
				this.scheduleService.saveSchedule(this.flattenSlots(scheduleDay, resourceId), resourceId);
			} else {
				window.alert('Unable to locate event index')
			}

		}

		private flattenSlots(scheduleObj, resourceId): any {
			console.log('flattenSlots', scheduleObj, resourceId);
			scheduleObj.slots = scheduleObj.events.filter((scheduleEvent => scheduleEvent.resourceId === resourceId)).map(scheduleEvent => (scheduleEvent.title)).join(', ');
			return scheduleObj;
		}
}
