
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { environment } from 'environments/environment';

const settingsQuery = gql
`
{
	settings
	{
	  settingName
	  settingValue
	}
}
`

const settingsMutation = gql`
  	mutation updateSettings(
		$settingName: String, $settingValue: String
	) {
    	updateSettings(
			settingName: $settingName, settingValue: $settingValue
		) {
			settingName
			settingValue
		}
  	}
`

@Injectable()
export class SettingsService implements OnDestroy {

	public loading = true;
	public settings = new BehaviorSubject<any>(null);
	private sub: Subscription;

	private lastVars = null;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.sub = this.apollo.watchQuery({
			query: settingsQuery,
			variables: this.lastVars,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.settings.next(JSON.parse(JSON.stringify(data['settings'])));
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public getSettingValueAsPromise(name: string): Promise<string> {

		return new Promise((resolve, reject) => {
			this.settings.subscribe(
				result => {
					if (result) {
						const r = result.find(x => x.settingName === name);
						console.log('setting', name, r, r===undefined)
                        if (environment.forwardsCompatibilityMode) {
                            if (name === 'KiThemeColour' && !r.settingValue.startsWith('#'))
                            {
                                resolve('#83cabe')
                            }
                            else if (name === 'KiDefaultTheme' && r === undefined)
                            {
                                resolve('default')
                            }
                            else
                            {
                                resolve(r.settingValue)
                            }
                        }
                        else
                        {
                            resolve(r.settingValue)
                        }
					}
				},
				error => {
					reject(error)
				})
		});
	}

	public getSettingValue(name: string): string {
		if (!this.settings.value) {
			return 'Loading...';
		}
		const setting = this.settings.value.find(x => x.settingName === name);
		return setting ? setting.settingValue : '';
	}

	public setSettingValue(name: string, value: any): void {
		if (this.settings.value) this.settings.value.find(x => x.settingName === name).settingValue = value;
	}

	public updateSettings(settingsToSave: any) {

		return new Promise((resolve, reject) => {
			settingsToSave.forEach((settingToSave, key, arr) => {

				const isLastRun = (Object.is(arr.length - 1, key));

				this.apollo.mutate({
					mutation: settingsMutation,
					variables: {
						settingName: settingToSave.settingName,
						settingValue: settingToSave.settingValue,
					},
					refetchQueries: [{
						query: settingsQuery,
						variables: this.lastVars,
					}],
				}).subscribe(({ data }) => {

					if (isLastRun) {
						// execute last item logic
						this.toastr.success('Changes Saved');
						resolve(data);
					}
					console.log('got data', data);
				}, (error) => {
					console.log(error);
					if (isLastRun) {
						// execute last item logic
						this.toastr.warning('There was an error saving changes', error);
						resolve(error);
					}
				});
			});

		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
