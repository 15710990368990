import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ServicesService } from '../../@core/data/services/services.service';
import { UserService } from '../../@core/data/users/user.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as copy from 'copy-to-clipboard';


@Component({
	selector: 'ngx-appointmentlinkgenerator',
	template: `
	<div class="row">
		<div class="col-lg-12">
			<nb-card>
				<nb-card-header>
					Appointment Link Generator
					<button *ngIf="showClose" class="close" aria-label="Close" (click)="closeModal();">
						<span aria-hidden="true">&times;</span>
					</button>
				</nb-card-header>
				<nb-card-body>
					<div class='alert alert-hint'>
						<details>
							<summary>
								<i class="fa fa-info-circle" aria-hidden="true"></i> Help!
							</summary>
							You can use this screen to send clients a direct booking link, even if your diary is closed to the public.
						</details>
					</div>

					<label>Service:</label>
					<div class="input-group mb-1">
						<select *ngIf="servicesList" name="type" class="form-control" [(ngModel)]="event.type" required>
							<option selected value style="display:none"></option>
							<option *ngFor="let r of servicesList" [attr.selected]="r?.serviceId === event.type ? true : null"
								[ngValue]="r"
							>
								{{r && r?.serviceName}} - ({{(this.servicesService.serviceLengthToString(r.serviceLength))}}, £{{r && r?.serviceDeposit}}/£{{r && r?.servicePrice}})
							</option>
						</select>
					</div>

					<label *ngIf="r?.serviceId">Service:</label>
					<div class="input-group mb-1" *ngIf="event?.type">
						<div class="p-1 d-inline" *ngFor="let addon of event?.type.serviceAddons">
							<input type="checkbox" [name]="addon.serviceName" [(ngModel)]="addon.serviceAddonSelected" />
							<label [for]="addon.serviceName"> {{addon.serviceName}} </label><br>
						</div>
					</div>

					<label for="from">Start:</label>
					<div class="input-group mb-1">
						<input *ngIf="event" mwlFlatpickr
							class="form-control"
							type="text"
							name="from"
							[(ngModel)]="event.start"
							altFormat="D J M Y, h:i K"
							dateFormat="Y-m-dTH:i"
						required>
					</div>

					<label>User:</label>
					<div class="input-group mb-1">
						<select *ngIf="userList" name="user" class="form-control" [(ngModel)]="event.resourceId" required>
							<option selected value style="display:none"></option>
							<option *ngFor="let r of userList"
								[ngValue]="r"
							>
								{{r && r?.userUsername}}
							</option>
						</select>
					</div>

					<span *ngIf="event.type && event.start && event.resourceId">
						<hr>
						<div class="form-row">
							<div class="form-group col-8">
								<label>Link:</label>
								<div class="input-group">
									<input name="bookingLink" type="text" class="form-control" value="{{getBookingLink()}}" readonly disabled #bookingLink />
								</div>
							</div>
							<div class="form-group col-2">
								<label>Copy:</label>
								<button type="button" class="btn btn-icon btn-success" (click)="copyToClipboard(bookingLink.value)">
									<i class="fa fa-clipboard" aria-hidden="true"></i>
								</button>
							</div>
							<div class="form-group col-2">
								<label>Open:</label>
								<button type="button" class="btn btn-icon btn-success" (click)="openInNewTab(bookingLink.value)">
									<i class="fa fa-external-link" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</span>
				</nb-card-body>
			</nb-card>
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentLinkGeneratorComponent implements OnInit, OnDestroy {
	private serviceSub: Subscription;
	public servicesList = null;

	@Input()
	public event: any = {
		type: null,
		start: null,
		end: null,
		client: null,
		resourceId: null,
	};

	public userList = null;

	@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
	@Input() showClose: boolean = false;

	constructor(
		public userService: UserService,
		private servicesService: ServicesService,
	) {
		this.servicesService.getServices();
	}

	ngOnInit() {

		this.serviceSub = this.servicesService.services.subscribe(value => {
			if (value) {
				// Consume
				this.servicesList = value.filter(svc => Number(svc.serviceEnabled) === 1);
			}
		});

		this.userService.getUserList().then(userList => {
			this.userList = userList;
		})
	}

	private fixDate(date) {
		return moment(date).format('YYYY-MM-DD HH:mm');
	}

	public getBookingLink(): string {

		if (!this.event.type || !this.event.resourceId) {
			return '';
		}
		const serviceList = [this.event.type.serviceId].concat(this.event.type.serviceAddons.filter(addon => addon.serviceAddonSelected).flatMap(x => x.serviceId)).join(',');
		console.log('serviceList', serviceList)

		return encodeURI(`https://${UserService.KiCustomer}.kibook.co.uk/#/book/${serviceList}/${this.fixDate(this.event.start)}/${this.event.resourceId.userId}`);
	}

	public copyToClipboard(item): void {
		copy(item);
	}

	public openInNewTab(inputElement) {
		window.open(inputElement, '_blank');
	}

	public closeModal(): void {
		this.formSubmit.next(false);
	}

	ngOnDestroy() {

		if (this.serviceSub) {
			this.serviceSub.unsubscribe();
			this.servicesList = null;
		}
	}
}
