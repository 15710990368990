
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const orderMutation = gql`
  mutation orderMutation(
			$storeorderId: Int, $storeorderNotes: String
		) {
		updateStoreorder(
			storeorderId: $storeorderId, storeorderNotes: $storeorderNotes
		) {
			storeorderId,
			storeorderNotes
		}
  	}
`

const ordersQuery = gql`
	query orderQuery($storeorderId: Int) {
		orders(storeorderId: $storeorderId)
		{
			storeorderId
			storeorderClient
			storeorderClientName
			storeorderPrice
			storeorderDate
			storeorderStatus
			storeorderVoucher
			{
				voucherCode
			}
		}
	}
`

@Injectable()
export class OrderService implements OnDestroy {

	public loading = true;
	public order = new BehaviorSubject<any>(null);
	private sub: Subscription;

	private lastVars = {}

	public static orderQuery = gql`
	query orderQuery($storeorderId: Int) {
		orders(storeorderId: $storeorderId)
		{
			storeorderId
			storeorderClient
			storeorderClientName
			storeorderPrice
			storeorderNotes
			storeorderDate
			storeorderItems
			storeorderStatus
			storeorderVoucher
			{
				voucherId
				voucherCode
			}
			storeorderStoreitems
			{
				storeitemId
				storeitemTitle
				storeitemPrice
				storeitemIsdigital
			}
			storeorderApptitems
			{
				apptId
				apptTitle
				apptStart
				apptPrice
				apptUsername
				apptStatus
			}
		}
	}
	`

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public getOrder(orderId): Promise<any> {

		return new Promise((resolve, reject) => {

			this.lastVars = {
				storeorderId: orderId,
			}

			this.sub = this.apollo.watchQuery({
				query: OrderService.orderQuery,
				variables: this.lastVars,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.order.next(JSON.parse(JSON.stringify(data['orders'])));
				resolve(this.order.value);
			}, (error) => {
				console.log(error);
				reject(error)
				this.toastr.warning('There was an error retrieving data', error);
			});

		})
	}

	public getOrders(): Promise<any> {

		return new Promise((resolve, reject) => {

			this.sub = this.apollo.watchQuery({
				query: ordersQuery,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.order.next(JSON.parse(JSON.stringify(data['orders'])));
				resolve(this.order.value);
			}, (error) => {
				console.log(error);
				reject(error)
				this.toastr.warning('There was an error retrieving data', error);
			});
		})
	}

	public processOrder(order: any): Promise<any> {

		console.log('processOrder', order);

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: orderMutation,
				variables: {
					storeorderId: order.storeorderId,
					storeorderNotes: order.storeorderNotes,
				},
				refetchQueries: [{
					query: OrderService.orderQuery,
					variables: this.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
