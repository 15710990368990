import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class MapService {

		constructor(private http: HttpClient) {}

		public geoCode(address: string) {
				return this.http.get(
					'https://maps.googleapis.com/maps/api/geocode/json?key='
					+ environment.gmapKey
					+ '&address='
					+ encodeURIComponent(address),
					)
						.map(res => (res as any))
						.map(result => {
								if (result.status !== 'OK') { throw new Error('unable to geocode address'); }
								const location = {
										address: result.results[0].formatted_address,
										latitude: result.results[0].geometry.location.lat,
										longitude: result.results[0].geometry.location.lng,
										viewPort: result.results[0].geometry.viewport,
								};

								return location;
						});
		}
}
