import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { UserService } from './@core/data/users/user.service';
import 'rxjs/add/operator/do'

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private nbAuthService: NbAuthService, private router: Router) {

		this.nbAuthService.onTokenChange()
		.subscribe((token: NbAuthJWTToken) => {
			if (token.isValid()) {
				const tokenData = token.getPayload().data;
				UserService.KiUserId = tokenData.KiUserId;
				UserService.KiCustomerId = tokenData.KiCustomerId;
				UserService.KiCustomer = tokenData.KiCustomer;
				this.storeFirebaseTopics(tokenData.KiTopicPrefix, tokenData.KiTopicName);
			}
		});
	}

	private storeFirebaseTopics(KiTopicPrefix: string, KiTopicName: string): void {
		localStorage.setItem('KiTopicPrefix', KiTopicPrefix);
		localStorage.setItem('KiTopicName', KiTopicName);
	}

	canActivate() {
		return this.nbAuthService.isAuthenticated()
			.do(authenticated => {
				if (!authenticated) {
					localStorage.removeItem('auth_app_token');
					this.router.navigate(['auth/login']);
				}
			});
	}
}
