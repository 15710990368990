import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'ngx-tiny-mce',
	template: `
	<editor
		[init]="this.tinymceSettings"
		[(ngModel)]="editorValue"
	></editor>`,
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

	// editor object
	public editorObject = new BehaviorSubject<any>(null);

	// keyup event
	@Output() editorKeyup = new EventEmitter<any>();

	// Set up Getter/Setter for value
	private _editorValue: any = undefined;
	public get editorValue(): any {
		return this._editorValue;
	}
	@Input()
	public set editorValue(value: any) {
		if (this._editorValue !== value) {
			// value
			this._editorValue = value;

			// output
			this.editorValueChange.emit(this._editorValue);
		}
	}
	@Output() editorValueChange = new EventEmitter<string>();

	@Input() editorHeight = '225px';

	public tinymceSettings = {
		height: this.editorHeight,
		width: '100%',
		menubar: false,
		statusbar: false,
		setup: (editor) => {
			editor.on('init', () => {
				this.editorObject.next(editor);
			});
			editor.on('blur', (e) => {
				this.editorKeyup.emit(editor.getContent());
			});
		},
		plugins: [
			// 	'advlist autolink lists link image charmap print preview anchor',
			// 	'searchreplace visualblocks code fullscreen',
			// 	'insertdatetime media table paste code help wordcount'
		],
		toolbar:
			'undo redo | formatselect | bold italic backcolor | \
			alignleft aligncenter alignright alignjustify | \
			bullist numlist outdent indent | removeformat | help',
		};

	constructor(
		// private host: ElementRef,
	) {}

	ngAfterViewInit() {}

	ngOnDestroy() {
		// tinymce.remove(this.editorObject);
	}
}
