import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { environment } from '../../environments/environment';

const socialLinks = [];

export class NbSimpleRoleProvider extends NbRoleProvider {
	getRole() {
		// here you could provide any role based on any auth flow
		return observableOf('guest');
	}
}

export const NB_CORE_PROVIDERS = [
	...DataModule.forRoot().providers,
	...NbAuthModule.forRoot({

		strategies: [
			NbPasswordAuthStrategy.setup({
				name: 'default',
				baseEndpoint: environment.apiurl,
				login: {
					endpoint: '',
				},
				requestPass: {
					endpoint: '',
					redirect: {
						success: null,
						failure: null,
					},
				},
				resetPass: {
					endpoint: '',
					redirect: {
						failure: null,
					},
				},
				logout: {
					endpoint: '',
				},
				token: {
					class: NbAuthJWTToken,
					key: 'token',
				},
			}),
		],
		forms: {
			login: {
				redirectDelay: 500,
				strategy: 'default',
				rememberMe: true,
				showMessages: {
					success: true,
					error: true,
				},
				socialLinks: socialLinks,
				defaultErrors: ['Login details are incorrect, please try again.'],
			},
			requestPassword: {
				strategy: 'default',
				showMessages: {
					success: true,
					error: true,
				},
			},
			resetPassword: {
				strategy: 'default',
				showMessages: {
					success: true,
					error: true,
				},
				defaultErrors: ['Expired password reset token.'],
			},
			logout: {
				strategy: 'default',
			},
		},
	}).providers,

	NbSecurityModule.forRoot({
		accessControl: {
			guest: {
				view: '',
			},
			user: {
				parent: 'guest',
				create: '*',
				edit: '*',
				remove: '*',
			},
		},
	}).providers,

	{
		provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
	},
];

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		NbAuthModule,
	],
	declarations: [],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
