
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';


const marketingQuery = gql`
query marketing(
		$action: String,
		$args: String
	) {
	marketing(action: $action, args: $args){
	  name
	  data
	}
  }
`

@Injectable()
export class MarketingService implements OnDestroy {

	public loading = true;
	public data = new BehaviorSubject<any>([]);
	private sub: Subscription;

	public status: string = '';

	constructor(
		private apollo: Apollo,
	) {}


	public getMarketing(): void {

		this.sub = this.apollo.watchQuery({
			query: marketingQuery,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				const payload = JSON.parse(JSON.stringify(data['marketing']));
				payload.data = JSON.parse(payload.data);
				this.data.next(payload);
				console.log(payload);
			}
		});
	}

	public updateMarketing(action: String, args: any = null): any {

		this.sub = this.apollo.watchQuery({
			query: marketingQuery,
			variables: {
				action: action,
				args: args,
			},
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				const payload = JSON.parse(JSON.stringify(data['marketing']));
				payload.data = JSON.parse(payload.data);
				return (payload);
			}
		});
	}

	public syncMembers(listName: String): void {

		this.sub = this.apollo.watchQuery({
			query: marketingQuery,
			variables: {
				action: 'addMembers',
				args: listName,
			},
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				const payload = JSON.parse(JSON.stringify(data['marketing']));
				payload.data = JSON.parse(payload.data);
				let completedAt = null;

				setInterval(() => {
					if (!completedAt) {
						this.apollo.watchQuery({
							query: marketingQuery,
							variables: {
								action: 'pollBatch',
								args: payload.data.id,
							},
							fetchPolicy: 'network-only',
						}).valueChanges.subscribe(({data, loading}) => {
							if (data) {
								const batchPayload = JSON.parse(JSON.stringify(data['marketing']));
								batchPayload.data = JSON.parse(batchPayload.data);
								completedAt = (batchPayload.data.completed_at);
								this.status = (batchPayload.data.status)
									+ ' (' + batchPayload.data.finished_operations
									+ '/' + batchPayload.data.total_operations + ')';
							}
						});
					}
				}, 1000);
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
