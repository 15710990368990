import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'ngx-auth-block',
	styleUrls: ['auth-block.component.scss'],
	template: `
    <nb-layout>
      <nb-layout-column>
        <ng-content></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
	encapsulation: ViewEncapsulation.None,
})
export class NgxAuthBlockComponent {
}
