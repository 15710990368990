import { Component, OnDestroy, OnInit } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import {
	NbMediaBreakpoint,
	NbMediaBreakpointsService,
	NbMenuItem,
	NbMenuService,
	NbSidebarService,
	NbThemeService,
	NbSearchService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';
import { CustomerService } from '../../../@core/data/settings/customer.service';
import { Subscription } from 'apollo-client/util/Observable';
import { ModalService } from '../../../@core/data/globals/modal.service';
import { SettingsService } from '../../../@core/data/settings/settings.service';
import { TutorialComponent } from '../../components/tutorialComponent/tutorialComponent.component';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'app/@core/data/users/user.service';

@Component({
	selector: 'ngx-sample-layout',
	styleUrls: ['./sample.layout.scss'],
	templateUrl: './sample.layout.html',
})
export class SampleLayoutComponent implements OnDestroy, OnInit {
	public customerName: string;
	public customerLogoHref: string;
	public dataSub: Subscription;

	public searchTerm = null;

	subMenu: NbMenuItem[] = [];
	layout: any = {};
	sidebar: any = {};

	private alive = true;

	currentTheme: string;

	constructor(protected stateService: StateService,
				protected menuService: NbMenuService,
				protected themeService: NbThemeService,
				protected bpService: NbMediaBreakpointsService,
				protected sidebarService: NbSidebarService,
				protected customerService: CustomerService,
				protected searchService: NbSearchService,
				protected modalService: ModalService,
				private userService: UserService,
				private settingsService: SettingsService,
				private sanitizer: DomSanitizer,
			) {
		this.stateService.onLayoutState()
			.pipe(takeWhile(() => this.alive))
			.subscribe((layout: string) => this.layout = layout);

		this.stateService.onSidebarState()
			.pipe(takeWhile(() => this.alive))
			.subscribe((sidebar: string) => {
				this.sidebar = sidebar;
			});

		this.sidebarService.onToggle().subscribe(sidebarState => {

			if (sidebarState.tag === 'menu-sidebar') {
				const targetElement = document.querySelector('#menu-sidebar .scrollable');
				const sidebarIsExpanded = document.getElementById('menu-sidebar').classList.contains('expanded');

				if (sidebarIsExpanded) {
					clearAllBodyScrollLocks();
				} else {
					disableBodyScroll(targetElement, {allowTouchMove: false, reserveScrollBarGap: true});
				}
			}
		});

		this.menuService.onItemSelect()
			.pipe(
				takeWhile(() => this.alive),
				withLatestFrom(this.themeService.onMediaQueryChange()),
				delay(20),
			)
			.subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

				if (bpTo.width <= 500) {
					this.sidebarService.toggle(true, 'menu-sidebar');
				}
			});

		this.searchService.onSearchSubmit().subscribe((data: any) => {
			this.searchTerm = data.term;
		})
	}

	public sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	ngOnInit() {

		this.userService.setUser(UserService.KiUserId);

		this.dataSub = this.customerService.customer.subscribe(value => {
			if (value) {
				const customer = value[0];
				this.customerName = customer.customerShortname;
				this.customerLogoHref = customer.customerLogoHref;

				this.settingsService.getSettingValueAsPromise('stripe_pk').then(result => {
					if (!result) {
						const state: string = btoa(JSON.stringify({
							'KiCustomerId': customer.customerId,
							'return_link': 'https://dashboard.kibook.co.uk/',
						}));

						console.log('prompting for stripe keys...', state)
						this.modalService.newModal(
							'KiBook Setup - Stripe',
							`
							KiBook integrates with Stripe to let you take payments and deposits. In order to use KiBook effectively, please click below to setup Stripe!<br>
							<a href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_DMrlZtfWYnyQnVzwGhn8hSqf5LYSHbP0&scope=read_write&redirect_uri=https://kibook.co.uk/wp/stripeAuth/?1=1&state=` + state + `">
								<button type="button" class="btn btn-primary btn-block m-1">
									Click here to set up Stripe.
								</button>
							</a>
							`, null, null, false,
						);
					}
				});

				if (Number(customer.customerTutorialComplete) !== 1) {
					this.modalService.dynamicModal(TutorialComponent, {showClose: false}, 'static');
				}
			}
		});

		this.settingsService.getSettingValueAsPromise('KiThemeColour').then( settingValue => {
			document.body.style.setProperty('--primary', settingValue);
		});
		this.settingsService.getSettingValueAsPromise('KiDefaultTheme').then( settingValue => {
			this.themeService.changeTheme(settingValue);
		});

		this.vanityLog('KiBook', 'https://dashboard.kibook.co.uk/assets/images/logo-48.png');
	}

	private vanityLog(title, url, size = 20) {
		const image = new Image();
		image.onload = () => {
			const style = [
				'font-size: 1px;',
				'padding: ' + size + 'px ' + size + 'px;',
				'background: url(' + url + ') no-repeat;',
				'background-size: contain;',
			].join(' ');
			console.log(title);
			console.log('%c ', style);
		};
		image.src = url;
	};

	ngOnDestroy() {
		if (this.dataSub) {
			this.dataSub.unsubscribe();
		}
		this.alive = false;
	}
}
