
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { ServicesService } from '../services/services.service';

const userMutation = gql`
mutation updateUser($userId: Int, $userUsername: String, $userImage: String, $userPassword: String, $userUsergroup: String,
	$userServices: String, $userNotes: String, $isDeleted: Int) {
	updateUser(
		userId: $userId,
		userUsername: $userUsername,
		userImage: $userImage,
		userPassword: $userPassword,
		userUsergroup: $userUsergroup,
		userServices: $userServices,
		userNotes: $userNotes,
		meta_isDeleted: $isDeleted
	) {
		userId
		userUsername
		userImage
		userServices
		userUsergroup
		userNotes
		userPermissions {
			usergroupId
			usergroupName
			usergroupSignin
			usergroupEditAlerts
			usergroupAddAlerts
			usergroupViewAlerts
			usergroupViewAllAlerts
			usergroupAddAppointments
			usergroupViewAppointments
			usergroupEditAppointments
			usergroupAddCategories
			usergroupEditCategories
			usergroupViewCategories
			usergroupAddClients
			usergroupEditClients
			usergroupViewClients
			usergroupEditCustomers
			usergroupViewCustomers
			usergroupAddLeave
			usergroupEditLeave
			usergroupViewLeave
			usergroupAddStoreorders
			usergroupEditStoreorders
			usergroupViewStoreorders
			usergroupAddPayments
			usergroupViewPayments
			usergroupEditPayments
			usergroupViewReports
			usergroupEditReports
			usergroupAddReports
			usergroupViewStoreitems
			usergroupEditStoreitems
			usergroupAddStoreitems
			usergroupAddServices
			usergroupEditServices
			usergroupViewServices
			usergroupAddUsergroups
			usergroupEditUsergroups
			usergroupViewUsergroups
			usergroupAddUsers
			usergroupEditUsers
			usergroupViewUsers
			usergroupEditVouchers
			usergroupAddVouchers
			usergroupViewVouchers
			usergroupEditsystemsettings
			usergroupViewsystemsettings
			usergroupEditmessageconfigs
			usergroupViewmessageconfigs
			usergroupEditmessagetemplates
			usergroupViewmessagetemplates
			usergroupEditbusinesssettings
			usergroupViewbusinesssettings
			usergroupEditAvailability
			usergroupViewAvailability
			usergroupAddAvailability
			usergroupEditallcalendars
			usergroupViewallcalendars
			usergroupViewanalytics
		}
		userServicesList{
			serviceId
			serviceCategory
			serviceName
			serviceUserCanPerform
		}
	}
}
`

const userListQuery = gql`
query userQuery($userId: Int) {
	users(userId: $userId) {
		userId
		userUsername
		userServices
	}
}
`

@Injectable()
export class UserService implements OnDestroy {

	public loading = true;
	private userSub: Subscription;

	// logged in user
	public user = new BehaviorSubject<any>(null);

	public static lastVars = null;
	public static KiUserId = null;
	public static KiCustomer = null;
	public static KiCustomerId = null;
	public static userQuery = gql`
	query userQuery($userId: Int) {
		users(userId: $userId) {
			isPrimaryUser
			userId
			userUsername
			userImage
			userServices
			userUsergroup
			userNotes
			userPermissions {
				usergroupId
				usergroupName
				usergroupSignin
				usergroupEditAlerts
				usergroupAddAlerts
				usergroupViewAlerts
				usergroupViewAllAlerts
				usergroupAddAppointments
				usergroupViewAppointments
				usergroupEditAppointments
				usergroupAddCategories
				usergroupEditCategories
				usergroupViewCategories
				usergroupAddClients
				usergroupEditClients
				usergroupViewClients
				usergroupEditCustomers
				usergroupViewCustomers
				usergroupAddLeave
				usergroupEditLeave
				usergroupViewLeave
				usergroupAddStoreorders
				usergroupEditStoreorders
				usergroupViewStoreorders
				usergroupAddPayments
				usergroupViewPayments
				usergroupEditPayments
				usergroupViewReports
				usergroupEditReports
				usergroupAddReports
				usergroupViewStoreitems
				usergroupEditStoreitems
				usergroupAddStoreitems
				usergroupAddServices
				usergroupEditServices
				usergroupViewServices
				usergroupAddUsergroups
				usergroupEditUsergroups
				usergroupViewUsergroups
				usergroupAddUsers
				usergroupEditUsers
				usergroupViewUsers
				usergroupEditVouchers
				usergroupAddVouchers
				usergroupViewVouchers
				usergroupEditsystemsettings
				usergroupViewsystemsettings
				usergroupEditmessageconfigs
				usergroupViewmessageconfigs
				usergroupEditmessagetemplates
				usergroupViewmessagetemplates
				usergroupEditbusinesssettings
				usergroupViewbusinesssettings
				usergroupEditAvailability
				usergroupViewAvailability
				usergroupAddAvailability
				usergroupEditallcalendars
				usergroupViewallcalendars
				usergroupViewanalytics
			}
			userServicesList{
				serviceId
				serviceCategory
				serviceName
				serviceUserCanPerform
			}
		}
	}
`

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		
	}

	public ngOnDestroy(): void {
		if (this.userSub) { this.userSub.unsubscribe(); };
	}

	public getUserList(): Promise<any> {

		return new Promise((resolve, reject) => {
			this.userSub = this.apollo.watchQuery({
				query: userListQuery,
				variables: {
					userId: null,
				},
				fetchPolicy: 'network-only',
			}).valueChanges.subscribe(({data, loading}) => {
				resolve(data['users']);
			}, (error) => {
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});

		});
	}

	public setUser(userId: Number = null): void {
		this.getUser(userId).then(userInfo => {
			this.user.next(userInfo);
		})
	}

	public getUser(userId: Number = null): Promise<any> {

		return new Promise((resolve, reject) => {

			UserService.lastVars = {
				userId: userId,
			}

			this.userSub = this.apollo.watchQuery({
				query: UserService.userQuery,
				variables: UserService.lastVars,
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				let result = data['users'];
				if (userId) {
					result = (data['users']).find(user => Number(user.userId) === Number(userId));
				}
				resolve(result);
			}, (error) => {
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});

		});
	}

	public updateUser(user: any) {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: userMutation,
				variables: {
					userId: user.userId,
					userUsername: user.userUsername,
					userPassword: user.userPassword,
					userImage: user.userImage,
					userUsergroup: user.userUsergroup,
					userServices: user.userServices,
					userNotes: user.userNotes,
					isDeleted: user.isDeleted ? 1 : 0,
				},
				refetchQueries: [{
					query: ServicesService.servicesQuery,
					variables: ServicesService.lastVars,
				},
				{
					query: UserService.userQuery,
					variables: {userId: null},
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				resolve(data['updateUser']);
				this.toastr.success('Changes Saved');
			}, (error) => {
				console.log(error);
				reject(error);
				this.toastr.warning('There was an error saving changes', error);
			});
		});
	}
}
