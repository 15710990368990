import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
	selector: 'ngx-bigheadeditor',
	styleUrls: ['./bigheadEditor.component.scss'],
	template: `
	<div class="row">
		<div class="col-lg-12">
			<nb-card>
				<nb-card-header>
					Avatar Generator
					<button *ngIf="showClose" class="close" aria-label="Close" (click)="closeModal();">
						<span aria-hidden="true">&times;</span>
					</button>
				</nb-card-header>
				<nb-card-body>
					<div class='alert alert-hint'>
						<details>
							<summary><i class="fa fa-info-circle" aria-hidden="true"></i> Help!</summary>
							Right-click or long-press to save your avatar.<br>
							You can then use this as your profile picture!
						</details>
					</div>
					<span id='previewImg'>
						<img class='previewImg' src="{{generateURL(bigheadData)}}" />
					</span>
					<hr>

					<div class="row">
						<div class="col-3">
							<label for="body">Lashes</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.lashes" name="lashes" >
									<option *ngFor="let optionName of bigheadOptions.lashes" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Body</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.body" name="body" >
									<option *ngFor="let optionName of bigheadOptions.body" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Lip Color</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.lipColor" name="lipColor" >
									<option *ngFor="let optionName of bigheadOptions.lipColor" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Skin Tone</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.skinTone" name="skinTone" >
									<option *ngFor="let optionName of bigheadOptions.skinTone" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<label for="body">Hair</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.hair" name="hair" >
									<option *ngFor="let optionName of bigheadOptions.hair" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Hair Color</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.hairColor" name="hairColor" >
									<option *ngFor="let optionName of bigheadOptions.hairColor" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Clothing</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.clothing" name="clothing" >
									<option *ngFor="let optionName of bigheadOptions.clothing" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Clothing Color</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.clothingColor" name="clothingColor" >
									<option *ngFor="let optionName of bigheadOptions.clothingColor" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<label for="body">Graphic</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.graphic" name="graphic" >
									<option *ngFor="let optionName of bigheadOptions.graphic" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Eyes</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.eyes" name="eyes" >
									<option *ngFor="let optionName of bigheadOptions.eyes" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Eyebrows</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.eyebrows" name="eyebrows" >
									<option *ngFor="let optionName of bigheadOptions.eyebrows" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Mouth</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.mouth" name="mouth" >
									<option *ngFor="let optionName of bigheadOptions.mouth" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<label for="body">Facial Hair</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.facialHair" name="facialHair" >
									<option *ngFor="let optionName of bigheadOptions.facialHair" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Accessory</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.accessory" name="accessory" >
									<option *ngFor="let optionName of bigheadOptions.accessory" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Hat</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.hat" name="hat" >
									<option *ngFor="let optionName of bigheadOptions.hat" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
						<div class="col-3">
							<label for="body">Hat Color</label>
							<div class="input-group">
								<select [(ngModel)]="bigheadData.hatColor" name="hatColor" >
									<option *ngFor="let optionName of bigheadOptions.hatColor" [ngValue]="optionName" selected="">{{optionName}}</option>
								</select>
							</div>
						</div>
					</div>
					<button class="btn btn-block btn-info" type="button" (click)="randomize(this.bigheadData)"> <i class="fa fa-random" aria-hidden="true"></i> Randomize </button>
					Powered by <a href="https://bigheads.io"><b>bigheads.io</b></a>
				</nb-card-body>
			</nb-card>
		</div>
	</div>
	`,
})

export class BigheadEditorComponent implements OnInit {

	public bigheadData = {
		accessory: 'none',
		body: 'breasts',
		circleColor: 'blue',
		clothing: 'vneck',
		clothingColor: 'black',
		eyebrows: 'raised',
		eyes: 'normal',
		facialHair: 'none',
		graphic: 'none',
		hair: 'bun',
		hairColor: 'orange',
		hat: 'none',
		hatColor: 'green',
		lashes: 'true',
		lipColor: 'turqoise',
		mask: 'true',
		mouth: 'openSmile',
		skinTone: 'light',
	};

	public bigheadOptions = {
		accessory: [
			'none',
			'roundGlasses',
			'tinyGlasses',
			'shades',
		],
		body: [
			'chest',
			'breasts',
		],
		circleColor: [
			'white',
			'blue',
			'black',
			'green',
			'red',
		],
		clothing: [
			'naked',
			'shirt',
			'dressShirt',
			'vneck',
			'tankTop',
			'dress',
		],
		clothingColor: [
			'white',
			'blue',
			'black',
			'green',
			'red',
		],
		eyebrows: [
			'raised',
			'leftLowered',
			'serious',
			'angry',
			'concerned',
		],
		eyes: [
			'normal',
			'leftTwitch',
			'happy',
			'content',
			'squint',
			'simple',
			'dizzy',
			'wink',
			'heart',
		],
		facialHair: [
			'none',
			'none2',
			'none3',
			'stubble',
			'mediumBeard',
		],
		graphic: [
			'none',
			'redwood',
			'gatsby',
			'vue',
			'react',
			'graphQL',
		],
		hair: [
			'none',
			'long',
			'bun',
			'short',
			'pixie',
			'balding',
			'buzz',
			'afro',
			'bob',
		],
		hairColor: [
			'blonde',
			'orange',
			'black',
			'white',
			'brown',
			'blue',
			'pink',
		],
		hat: [
			'none',
			'none2',
			'none3',
			'none4',
			'none5',
			'beanie',
			'turban',
		],
		hatColor: [
			'white',
			'blue',
			'black',
			'green',
			'red',
		],
		lashes: ['true', 'false'],
		lipColor: [
			'red',
			'purple',
			'pink',
			'turqoise',
			'green',
		],
		mouth: [
			'grin',
			'sad',
			'openSmile',
			'lips',
			'open',
			'serious',
			'tongue',
		],
		skinTone: [
			'light',
			'yellow',
			'brown',
			'dark',
			'red',
			'black',
		],
		mask: ['true', 'false'],
	};

	@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
	@Input() showClose: boolean = false;

	public sourceURL: string = '';

	constructor() {	}

	public closeModal(): void {
		this.formSubmit.next(false);
	}

	public generateURL(object) {
		return `https://bigheads.io/svg/?${new URLSearchParams(object).toString()}`;
	}

	public randomize(bigheadData) {

		Object.entries(bigheadData).forEach(property => {
			const propertyName = property[0];
			const possibleValues = this.bigheadOptions[propertyName];
			const random = Math.floor(Math.random() * possibleValues.length);

			console.log(`setting ${propertyName} to ${possibleValues[random]}`);
			this.bigheadData[propertyName] = possibleValues[random];
		});

		return bigheadData;
	}

	public ngOnInit() {

	}
}
