import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import * as moment from 'moment';

@Component({
	selector: 'ngx-calendar',
	template: `
		<div class='toolbar'>
			<div class="row">
				<div class="col-3 p-0">
					<select (change)="setCalendarDate('month', $event.target.value, calendarDate().full)" >
						<option *ngFor="let month of monthNames; let i = index;" value="{{i}}" [attr.selected]="calendarDate().monthNum == i ? true : null">
							{{month}}
						</option>
					</select>
				</div>
				<div class="col-3 p-0">
					<select (change)="setCalendarDate('year', $event.target.value, calendarDate().full)" >
						<option *ngFor="let year of yearRange; let i = index;" value="{{year}}" [attr.selected]="calendarDate().yearNum == year ? true : null">
							{{year}}
						</option>
					</select>
				</div>
				<div class="col-3 p-0">
					<select (change)="setCalendarView($event.target.value)" >
						<option *ngFor="let viewType of objectKeys(viewTypes)" value="{{viewType}}" [attr.selected]="getCalendarView().name == viewType ? true : null">
							{{viewTypes[viewType]}}
						</option>
					</select>
				</div>
				<div class="col-3 p-0">
					<details class="float-right bg-white position-absolute noExpando">
						<summary>
							<span class="btn btn-secondary btn-sm">
								<i class="eva eva-funnel-outline"></i>
							</span>
						</summary>
						<label *ngFor="let type of eventTypes" class="p-1 d-block bg-white">
							{{type.name}}
							<input type="checkbox"
								name="{{type.class}} calendarToggle"
								[checked]="type.checked"
								(change)="toggleTypes(type.class)"/>
						</label>
					</details>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<select
						*ngIf="resources.length > 1 && showResourceSelector"
						(change)="selectResource($event.target.value)"
						class="form-control">
							<option value="0">Show All</option>
							<option *ngFor="let r of resources" value="{{r?.id}}"
								[attr.selected]="selectedResourceId == r.id ? true : null">
									{{r?.title}}
							</option>
					</select>
				</div>
			</div>
		</div>

        <button type="button" class="btn btn-primary btn-block m-1" (click)="incrementCalendarTime('minTime', -90);">Show Earlier</button>
		<div style="position:absolute;" [class.loader]="isLoading"></div>
		<div [class.loading]="isLoading" [class.not-loading]="!isLoading" id='fullcalendar'></div>
		<button type="button" class="btn btn-primary btn-block m-1" (click)="incrementCalendarTime('maxTime', 90);">Show Later</button>
	`,
	encapsulation: ViewEncapsulation.None,
	styles: [
		'div.toolbar{text-align: center; padding: 2.5px;}',
		'div.toolbar input, div.toolbar select{text-align: center; width: 100%; padding: 2.5px; margin: 2.5px; border-radius: 5px;}',
		'div.loading .fc-content-skeleton{ display: none; }',
		'div.loading *{ border: 0px; }',
		'.toolbar details{margin-bottom:-1000px;width:100%;}',
	],
})
export class CalendarComponent implements OnInit, OnDestroy {

	// Set up static var for reference to calendar parent element
	public static calenderElement: JQuery;

	// Status ref and get setter for calendar loading status
	get isLoading() {
		return CalendarComponent.isLoading;
	}
	set isLoading(status) {
		CalendarComponent.isLoading = status;
	}
	public static isLoading: boolean = false;


	// Prepare inputs for component
	@Input() options: any;

	// bool: should show resource selector above calendar
	@Input() showResourceSelector: boolean = true;

	// int: Selected resource ID by default (otherwise shows all)
	@Input() selectedResourceId: number = 0;
	@Output() resourceChanged = new EventEmitter<number>();


	// Set up Getter/Setter for resources
	private _resources: any = undefined;
	public get resources(): any {
			return this._resources;
	}
	@Input()
	public set resources(value: any) {
		if (this._resources !== value) {
			this._resources = value;
		}
	}

	// Set up Getter/Setter for events
	private _events: any;
	public get events(): any {
		return this._events;
	}
	@Input()
	public set events(value: any) {
		if (this._events !== value) {
			this._events = value;
			this.renderEvents();
			(this.selectedResourceId) ? this.selectResource(this.selectedResourceId) : null;
		}
	}

	// should probably use a library for this, or no?
	public monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	public yearRange = [];
	public viewTypes = {'listMonth': 'List Month', 'month': 'Month', 'agendaWeek': 'Week', 'agendaThreeDay': '3 Day', 'agendaDay': 'Day'}
	public eventTypes = [
		{
			name: 'Available',
			class: 'fc-available',
			checked: true,
		},
		{
			name: 'Leave',
			class: 'fc-leave',
			checked: true,
		},
		{
			name: 'Cancelled',
			class: 'fc-cancelled',
			checked: true,
		},
		{
			name: 'Booked',
			class: 'fc-booked',
			checked: true,
		},
	];

	constructor() {}

	// Initialize
	public ngOnInit() {

		// check options
		this.options = this.vanityOptions(this.options);

		// Select fullcalendar parent element
		CalendarComponent.calenderElement = $('#fullcalendar');

		// Initialize the calendar with passed through options
		CalendarComponent.calenderElement.fullCalendar(this.options);

	}

	// for 'smart' defaults
	private vanityOptions(userOpts) {

		// todo if defaultView isnotnull but also is just a plain value,
		// we should auto do the local storage is what im trying to say
		if (userOpts.defaultView == null) {
			userOpts.defaultView = (localStorage.getItem('fcDefaultUserView') !== null ? (localStorage.getItem('fcDefaultUserView')) : 'agendaThreeDay');
		}

		if (userOpts.defaultDate == null) {
			userOpts.defaultDate = (localStorage.getItem('fcDefaultStartDate') !== null ? new Date(localStorage.getItem('fcDefaultStartDate')) : new Date());
		}

		// for remembering the start date
		if (userOpts.viewRender == null) {
			userOpts.viewRender = (view, element) => {

				//
				userOpts.defaultDate = new Date(view.start);
				localStorage.setItem('fcDefaultStartDate', view.start);

				//
				userOpts.defaultView = view.name;
				localStorage.setItem('fcDefaultUserView', view.name);
			}
		}

		// no loading event defined
		if (userOpts.loading == null && userOpts.eventAfterAllRender == null) {

			// default one trips isLoading bool, associated CSS
			userOpts.loading = (isLoading) => {
				// ignore false calls here
				if (isLoading) { this.isLoading = true; }
			};
			userOpts.eventAfterAllRender = (view) => {
				this.isLoading = false;

				this.applyTypeFilter();
			};
		}

		return userOpts;
	}

	public selectResource(resId): void {

		// Pull in all valid resources
		CalendarComponent.calenderElement.fullCalendar( 'refetchResources' );

		// Store this mostly for debugging purposes
		CalendarComponent.calenderElement.fullCalendar('getResources');

		// blah blah
		if (this.selectedResourceId !== resId) {

			// update ref
			this.selectedResourceId = resId;
			this.resourceChanged.emit(this.selectedResourceId);

			// refetch events
			CalendarComponent.calenderElement.fullCalendar(`refetchEvents`);
		}
		
	}

	private refreshCalendar(_callback) {

		// Remove anything currently on the calendar
		CalendarComponent.calenderElement.fullCalendar('removeEvents', function () { return true; });
		// Add new events to calendar
		CalendarComponent.calenderElement.fullCalendar('addEventSource', this.events);

		_callback();
	}

	private resizeCalendar() {
		// Check if we have any new events
		if (this.events.length > 0) {

			// Get min, max event time for calendar resize
			let MinTime = null;
			let MaxTime = null;
			this.events.forEach((eventObj) => {
				const startTime = eventObj.start.split(' ')[1];
				const endTime = eventObj.end.split(' ')[1];
				if (MinTime == null || (startTime < MinTime)) { MinTime = startTime}
				if (MaxTime == null || (endTime > MaxTime)) { MaxTime = endTime}
			});

			// null check ?
			if (MinTime === MaxTime) {
				MinTime = '09:00';
				MaxTime = '17:00';
			}

			// resize calendar
			if (MinTime) {CalendarComponent.calenderElement.fullCalendar('option', 'minTime', MinTime); }
			if (MaxTime) {CalendarComponent.calenderElement.fullCalendar('option', 'maxTime', MaxTime); }

			this.incrementCalendarTime('minTime', -30)
			this.incrementCalendarTime('maxTime', 60)

		}
	}

	public getEventsByYYYYMMDD(YYYYMMDD, resourceId: Number = null): any {

		const result = CalendarComponent.calenderElement.fullCalendar('clientEvents', (evt) => {

			const eventStart = (typeof evt.start === 'object') ? evt.start.format('YYYY-MM-DD') : evt.start;

			const dateMatches = eventStart === YYYYMMDD;

			// if there's no resourceId specified then just whatever
			console.log('resourceId', resourceId);
			console.log('evt.resourceId', evt.resourceId);
			const resourceMatches = (!resourceId) ? true : ((Number(evt.resourceId) === Number(resourceId)) ? true : false);

			const isAvailable = (evt.className === 'fc-available' || evt.className.includes('fc-available'));

			console.log(`YYYYMMDD: ${YYYYMMDD}, eventStart: ${eventStart}, dateMatches: ${dateMatches}, resourceMatches: ${resourceMatches}, isAvailable: ${isAvailable}` + ((dateMatches && isAvailable) ? ` ( adding: ${evt.start.format()} - ${evt.end.format()})` : null));

			return (dateMatches && isAvailable && resourceMatches);
		});

		return result;
	}

	public renderEvents(): void {

		// Verify the calendar has been initialized before bothering
		if (CalendarComponent.calenderElement) {
			this.refreshCalendar(() => {
				this.resizeCalendar();
				this.updateYearRange();
			});
		}
	}

	public getCalendarView() {
		return CalendarComponent.calenderElement.fullCalendar('getView');
	}

	public setCalendarView(setCalendarView) {
		CalendarComponent.calenderElement.fullCalendar('changeView', setCalendarView);
	}

	public updateYearRange() {
		const size = 10;
		const currentYear = (this.vanityOptions(this.options).defaultDate).getFullYear();
		this.yearRange = [...Array(size)].map((a, b) => { const c = Math.floor(b - (size / 2)); a = (currentYear - c); return (a)});
	}

	public calendarDate() {
		if (CalendarComponent.calenderElement) {

			const parsed = CalendarComponent.calenderElement.fullCalendar('getDate');

			const r = {
				monthNum: parsed.month(),
				monthName: parsed.format('MMMM'),
				yearNum: parsed.format('YYYY'),
				full: parsed,
			};

			return r;
		}

	}

	public setCalendarDate(type, newValue: number, currentDate) {
		console.log('setCalendarDate', newValue, type, currentDate);

		// moment.set does not work
		// so build the current YM in string
		const currentYM = currentDate.year() + '-' + currentDate.format('MM');
		let targetYM = currentYM;
		console.log('currentYM', currentYM);

		switch (type) {
			case 'month':

				// off by one fix
				// keeps concatenating, force numerical
				const newMonth = Number(newValue) + 1;

				// ensure leading zeros
				targetYM = currentDate.year() + '-' + newMonth.toString().padStart(2, '0');

				break;
			case 'year':

				// ensure leading zeros
				targetYM = newValue + '-' + currentDate.format('MM');

				break;
			default:
				alert('this aint it dawg');
				break;
		}

		// build target YM in string
		console.log('targetYM', targetYM);

		// replace date
		const newDate = currentDate.format().replace(currentYM, targetYM);
		console.log('gotoDate', newDate)

		if (!moment(newDate).isValid()) {
			const err = 'bad date: ' + newDate;
			alert(err);
			console.log(err)
		}

		// update calendar
		CalendarComponent.calenderElement.fullCalendar('gotoDate', newDate);
	}

	public incrementCalendarTime(valueToSet, minuteDiff) {

		// Grab current time
		let calendarTime = CalendarComponent.calenderElement.fullCalendar('option', valueToSet);

		// Work out time in minutes, with added time
		const timeInMinutes = ((parseInt(calendarTime.split(':')[0], 10) * 60) + parseInt(calendarTime.split(':')[1], 10) + minuteDiff);

		// Update CalenderTime
		calendarTime = (Math.floor(timeInMinutes / 60)) + ':' + (timeInMinutes % 60);

		// updated calendar component
		CalendarComponent.calenderElement.fullCalendar('option', valueToSet, calendarTime);
	}

	public toggleTypes(typeName): void {

		// load filter from localstorage
		let filteredTypes = JSON.parse(localStorage.getItem('fc.hidden')) || [];

		// yadda yadda
		if (filteredTypes.includes(typeName)) {
			filteredTypes = filteredTypes.filter(entry => entry !== typeName);
		} else {
			filteredTypes.push(typeName);
		}

		// save back to localstorage
		localStorage.setItem('fc.hidden', JSON.stringify(filteredTypes));

		// run the filter
		this.applyTypeFilter();
	}

	public applyTypeFilter(): void {

		// load filter data from localstorage
		const filteredTypes = JSON.parse(localStorage.getItem('fc.hidden')) || [];

		// loop over all known eventtypes
		this.eventTypes.forEach( type => {

			// toggle if enabled/disabled
			type.checked = !filteredTypes.includes(type.class);

			// update existing elements
			Array.from(document.querySelectorAll(`.${type.class}`)).forEach(e => {
				(type.checked) ? e.removeAttribute('hidden') : e.setAttribute('hidden', 'true');
			});
		});
	}

	public objectKeys(obj) {
		return (obj == null) ? [] : Object.keys(obj);
	}

	// Cleanup
	public ngOnDestroy() {

	}
}
