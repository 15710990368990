
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { UserService } from '../users/user.service';

const customerQuery = gql`
query customerQuery(
	$customerId: Int
)
{
	customers(customerId: $customerId)
	{
		customerId
		customerShortname
		customerWebsite
		customerDescription
		customerEmail
		customerPhone
		customerAddress
		customerAddressDirections
		customerShowAddressPublicly
		customerLogo
		customerLogoHref,
		customerTutorialComplete
		customerTier
		billingInfo
	}
}
`

const createBillingPortalInstance = gql`
query customerQuery(
	$customerId: Int
)
{
	customers(customerId: $customerId)
	{
		customerId
		customerShortname
		billingPortalInstance
	}
}
`

const customerMutation = gql`
  mutation updateCustomer(
			$customerId: Int!, $customerDescription: String, $customerWebsite: String, $customerEmail: String,
			$customerPhone: String, $customerAddress: String, $customerAddressDirections: String, $customerShowAddressPublicly: Int,
			$customerLogo: String, $customerTutorialComplete: Int
		) {
    	updateCustomer(
				customerId: $customerId, customerWebsite: $customerWebsite, customerDescription: $customerDescription,
				customerEmail: $customerEmail, customerPhone: $customerPhone, customerAddress: $customerAddress
				customerAddressDirections: $customerAddressDirections, customerShowAddressPublicly: $customerShowAddressPublicly, customerLogo: $customerLogo, customerTutorialComplete: $customerTutorialComplete
			) {
				customerId
				customerShortname
				customerWebsite
				customerDescription
				customerEmail
				customerPhone
				customerAddress
				customerAddressDirections
				customerShowAddressPublicly
				customerLogo,
				customerLogoHref,
				customerTutorialComplete,
				customerTier,
				billingInfo
			}
  	}
`

@Injectable()
export class CustomerService implements OnDestroy {

	public loading = true;
	public customer = new BehaviorSubject<any>(null);
	private sub: Subscription;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.sub = this.apollo.watchQuery({
			query: customerQuery,
			variables: {
				customerId: UserService.KiCustomerId,
			},
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.customer.next(JSON.parse(JSON.stringify(data['customers'])));
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}

	public getBillingPortal(): Promise<any> {

		const customerId = this.customer.value[0].customerId;

		return new Promise((resolve, reject) => {
			this.sub = this.apollo.watchQuery({
				query: createBillingPortalInstance,
				variables: {
					customerId: customerId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				console.log('billingPortalInstance', data['customers'][0].billingPortalInstance)
				resolve(data['customers'][0].billingPortalInstance);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error)
			});

		})
	}

	public isMultiUser(): boolean {
		return this.customer.value && Number(this.customer.value[0].customerTier) > 1;
	}

	public updateCustomer(customer: any) {
		this.apollo.mutate({
			mutation: customerMutation,
			variables: {
				customerId: customer.customerId,
				customerDescription: customer.customerDescription,
				customerWebsite: customer.customerWebsite,
				customerEmail: customer.customerEmail,
				customerPhone: customer.customerPhone,
				customerAddress: customer.customerAddress,
				customerAddressDirections: customer.customerAddressDirections,
				customerShowAddressPublicly: customer.customerShowAddressPublicly ? 1 : 0,
				customerLogo: customer.customerLogo,
				customerTutorialComplete: customer.customerTutorialComplete,
			},
		}).subscribe(({ data }) => {
			console.log('got data', data);
			this.toastr.success('Changes Saved');
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error saving changes', error);
		});
	}
}
