import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { NgxAuthBlockComponent } from './components/auth/auth-block/auth-block.component';
import { NgxLoginComponent } from './components/auth/login/login.component';
import { NgxRegisterComponent } from './components/auth/register/register.component';
import { NgxLogoutComponent } from './components/auth/logout/logout.component';
import { NgxRequestPasswordComponent } from './components/auth/request-password/request-password.component';
import { NgxResetPasswordComponent } from './components/auth/reset-password/reset-password.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { ColorPickerModule } from 'ngx-color-picker'
import { Ng2CompleterModule } from 'ng2-completer';
import { FlatpickrModule } from 'angularx-flatpickr';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import {
	NbAlertModule,
	NbActionsModule,
	NbCardModule,
	NbLayoutModule,
	NbMenuModule,
	NbRouteTabsetModule,
	NbSearchModule,
	NbSidebarModule,
	NbTabsetModule,
	NbThemeModule,
	NbUserModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
	NbStepperModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import {
	FooterComponent,
	HeaderComponent,
	SearchInputComponent,
	ThemeSettingsComponent,
	SwitcherComponent,
	LayoutDirectionSwitcherComponent,
	ThemeSwitcherComponent,
	TinyMCEComponent,
	ThemeSwitcherListComponent,
	ButtonViewComponent,
	CalendarComponent,
	YearCalendarComponent,
	FloatingActionBarComponent,
	SearchResultViewComponent,
	ModalComponent,
	NbInputModule,
} from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, SanitizeHtmlPipe } from './pipes';
import {
	SampleLayoutComponent,
} from './layouts';

import { AppointmentLinkGeneratorComponent } from '../pages/appointments/AppointmentLinkGenerator.component'
import { AppointmentAddComponent } from '../pages/appointments/appointmentAdd.component';
import { LeaveViewComponent } from '../pages/leave/leaveview/leaveview.component';
import { CategoryViewComponent } from '../pages/settings/booking/categories/categoryview/categoryview.component';
import { BigheadEditorComponent } from './components/bigheadEditor/bigheadEditor.component';
import { TutorialComponent } from './components/tutorialComponent/tutorialComponent.component';
import { ScheduleComponent } from '../pages/settings/booking/schedule/schedule.component';
import { PaymentAddComponent } from '../pages/payments/paymentadd/paymentadd.component';
import { ClientAddComponent } from 'app/pages/clients/clientadd/clientadd.component';
import { VouchersAddComponent } from 'app/pages/vouchers/voucheradd/voucheradd.component';

const BASE_MODULES = [
	RouterModule, NbStepperModule,
	CommonModule, FormsModule,
	ReactiveFormsModule, Ng2SmartTableModule,
	JwBootstrapSwitchNg2Module, ColorPickerModule,
	Ng2CompleterModule, FlatpickrModule, EditorModule,
];

const NB_MODULES = [
	NbInputModule,
	NbAlertModule,
	NbCardModule,
	NbLayoutModule,
	NbTabsetModule,
	NbRouteTabsetModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
	NgbModule,
	NbSecurityModule, // *nbIsGranted directive
];

const COMPONENTS = [
	SwitcherComponent,
	LayoutDirectionSwitcherComponent,
	ThemeSwitcherComponent,
	ThemeSwitcherListComponent,
	HeaderComponent,
	FooterComponent,
	SearchInputComponent,
	ThemeSettingsComponent,
	TinyMCEComponent,
	ButtonViewComponent,
	CalendarComponent,
	YearCalendarComponent,
	FloatingActionBarComponent,
	ModalComponent,
	SampleLayoutComponent,
	SearchResultViewComponent,
	AppointmentAddComponent,
	VouchersAddComponent,
	PaymentAddComponent,
	ClientAddComponent,
	CategoryViewComponent,
	AppointmentLinkGeneratorComponent,
	BigheadEditorComponent,
	LeaveViewComponent,
	TutorialComponent,
	ScheduleComponent,
];

const ENTRY_COMPONENTS = [
	ThemeSwitcherListComponent,
	ButtonViewComponent,
	CalendarComponent,
	YearCalendarComponent,
	FloatingActionBarComponent,
	ModalComponent,
	SearchResultViewComponent,
	AppointmentAddComponent,
	VouchersAddComponent,
	PaymentAddComponent,
	ClientAddComponent,
	CategoryViewComponent,
	AppointmentLinkGeneratorComponent,
	BigheadEditorComponent,
	LeaveViewComponent,
	TutorialComponent,
	ScheduleComponent,
];

const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	SanitizeHtmlPipe,
];

const AUTH = [
	NgxAuthBlockComponent,
	NgxLoginComponent,
	NgxRegisterComponent,
	NgxRequestPasswordComponent,
	NgxResetPasswordComponent,
	NgxLogoutComponent,
]

const NB_THEME_PROVIDERS = [
	...NbThemeModule.forRoot(
		{
			name: 'default',
		},
		[ ],
	).providers,
	...NbSidebarModule.forRoot().providers,
	...NbMenuModule.forRoot().providers,
	{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
];

@NgModule({
	imports: [...BASE_MODULES, ...NB_MODULES],
	exports: [
		...BASE_MODULES,
		...NB_MODULES,
		...COMPONENTS,
		...PIPES,
	],
	declarations: [
		...COMPONENTS,
		...PIPES,
		...AUTH,
	],
	entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: ThemeModule,
			providers: [...NB_THEME_PROVIDERS],
		};
	}
}
