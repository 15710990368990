import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users/user.service';
import { StateService } from './state.service';
import { CustomerService } from './settings/customer.service';
import { PanelService } from './globals/panel.service';
import { ClientService } from './clients/client.service';
import { OrderService } from './orders/order.service';
import { AppointmentService } from './appointments/appointment.service';
import { AppointmentsService } from './appointments/appointments.service';
import { AvailabilityService } from './availability/availability.service';
import { CategoriesService } from './services/categories.service';
import { ServicesService } from './services/services.service';
import { ScheduleService } from './availability/schedule.service';
import { ModalService } from './globals/modal.service';
import { SettingsService } from './settings/settings.service';
import { UsergroupService } from './users/usersgroup.service';
import { AlertService } from './globals/alerts.service';
import { MapService } from './globals/map.service';
import { StoreService } from './store/store.service';
import { MarketingService } from './marketing/marketing.service';
import { MailerService } from './globals/mailer.service';
import { PaymentsService } from './store/payments.service';
import { VouchersService } from './store/vouchers.service';
import { SearchService } from './globals/search.service';
import { ReportService } from './reports/report.service';

const SERVICES = [

	AlertService,
	MapService,
	SearchService,

	AvailabilityService,
	ScheduleService,

	CategoriesService,
	ServicesService,

	AppointmentService,
	AppointmentsService,

	ClientService,

	MarketingService,
	MailerService,

	OrderService,
	StoreService,

	PaymentsService,
	VouchersService,

	UserService,
	UsergroupService,
	CustomerService,

	ReportService, 
	
	SettingsService,

	PanelService,
	ModalService,

	StateService,
];

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		...SERVICES,
	],
})
export class DataModule {
	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: DataModule,
			providers: [
				...SERVICES,
			],
		};
	}
}
