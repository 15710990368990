
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';

const voucherMutation = gql`
mutation updateVoucher($voucherId: Int, $voucherCode: String, $voucherType: String, $voucherUses: Int, $voucherValue: String, $voucherNotes: String, $voucherExpiry: String, $isDeleted: Int) {
    updateVoucher(voucherId: $voucherId, voucherCode: $voucherCode, voucherType: $voucherType, voucherUses: $voucherUses, voucherValue: $voucherValue, voucherNotes: $voucherNotes, voucherExpiry: $voucherExpiry, meta_isDeleted: $isDeleted) {
        voucherId
        voucherCode
        voucherType
		voucherUses
        voucherValue
		voucherNotes
		voucherExpiry
    }
}
`

const vouchersQuery = gql`
query vouchers($voucherId: Int) {
    vouchers(voucherId: $voucherId) {
        voucherId
        voucherCode
        voucherType
		voucherUses
        voucherNotes
    }
}
`

const voucherQuery = gql`
query voucher($voucherId: Int) {
    vouchers(voucherId: $voucherId) {
        voucherId
        voucherCode
        voucherType
		voucherUses
		voucherUsage {
			storeorderId
			storeorderClientName
			storeorderDate
		}
		voucherExpiry
        voucherValue
        voucherNotes
    }
}
`

@Injectable()
export class VouchersService implements OnDestroy {

	public loading = true;
	public vouchers = new BehaviorSubject<any>(null);
	private sub: Subscription;
	private lastVars: any = {};

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public getVouchers(voucherIds: any[] = null): void {

		const voucherIdString = (voucherIds) ? (voucherIds).join(',') : null;

		this.lastVars = {
			voucherId: voucherIdString,
		};

		this.sub = this.apollo.watchQuery({
			query: vouchersQuery,
			variables: this.lastVars,
			fetchPolicy: 'network-only',
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.vouchers.next(data['vouchers']);
			console.log('this.vouchers', this.vouchers.value);
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public getVoucher(voucherId): Promise<any> {

		return new Promise((resolve, reject) => {
			this.apollo.watchQuery({
				query: voucherQuery,
				variables: {
					voucherId: voucherId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				resolve(JSON.parse(JSON.stringify(data['vouchers']))[0]);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		})
	}

	public updateVoucher(voucher: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: voucherMutation,
				variables: {
					voucherId: voucher.voucherId,
					voucherCode: voucher.voucherCode,
					voucherType: voucher.voucherType,
					voucherUses: voucher.voucherUses,
					voucherValue: voucher.voucherValue,
					voucherNotes: voucher.voucherNotes,
					voucherExpiry: voucher.voucherExpiry,
					isDeleted: voucher.isDeleted,
				},
				refetchQueries: [{
					query: vouchersQuery,
					variables: null,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data.updateVoucher[0]);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
