import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../@theme/components/modal/modal.component';

@Injectable()
export class ModalService {

		constructor(private ngbModal: NgbModal) { }

		public newModal(title, text, callback = function(element, args, evt){}, context: any = null, showClose = true) {

			ModalComponent.callBackFunc = callback;
			ModalComponent.contextRef = context;

			const activeModal = this.ngbModal.open(ModalComponent, {
				size: 'lg',
				backdrop: 'static',
				container: 'nb-layout',
			});

			activeModal.componentInstance.showClose = showClose;
			activeModal.componentInstance.modalHeader = title;
			activeModal.componentInstance.modalContent = text;
			activeModal.componentInstance.modalButton = 'Close';

			return activeModal;
		}

		public dynamicModal(component: any, args: any, backdrop: boolean | 'static' = false): Promise<any> {

			return new Promise((resolve, reject) => {
				const activeModal = this.ngbModal.open(component, {
					size: 'lg',
					backdrop: backdrop,
					container: 'nb-layout',
				});
	
				// if the component has a formSubmit property, watch it to close the modal
				if (activeModal.componentInstance.hasOwnProperty('formSubmit')) {
					activeModal.componentInstance.formSubmit.subscribe((formSubmit) => {
						if (formSubmit === false) {
							reject(formSubmit);
						} else {
							resolve(formSubmit);
						}
						activeModal.close();
					});
				}
	
				if (activeModal.componentInstance.hasOwnProperty('showClose')) {
					activeModal.componentInstance.showClose = true;
				}
	
				// map arguments to component properties where possible
				Object.keys(args).forEach((key) => {
					console.log('setting key: ', key, args[key])
					activeModal.componentInstance[key] = args[key];
				});
	
				return activeModal;
			});
		
		}
}
