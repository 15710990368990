import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'ngx-footer',
	styleUrls: ['./footer.component.scss'],
	template: `
		<small>
			<span class="created-by">
				<a href="https://kibook.co.uk">KiBook {{commitHash}}</a> | <a href="https://clydesites.co.uk">Clydesites</a> | <a href="https://akveo.com" target="_blank">ngx-admin</a>
			</span>
		</small>
		<div class="socials">
			<a href="mailto:contact@kibook.co.uk" target="_blank" class="eva eva-email-outline"></a>
			<a href="https://www.facebook.com/kibookcouk" target="_blank" class="eva eva-facebook-outline"></a>
			<a href="https://instagram.com/kibookcouk" target="_blank" class="eva eva-camera-outline"></a>
		</div>
	`,
})
export class FooterComponent {

		public commitHash;

		constructor() {
			this.commitHash = environment.commitHash;
		}
}
