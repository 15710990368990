import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';


@Component({
	selector: 'ngx-button-view',
	template: `
  <i (click)="onClick()" class="fa {{ renderValue }}" aria-hidden="true"></i>
  `,
})
export class ButtonViewComponent implements ViewCell, OnInit {
	renderValue: string;

	@Input() value: string | number;
	@Input() rowData: any;

	@Output() save: EventEmitter<any> = new EventEmitter();

	ngOnInit() {
		this.renderValue = (this.value) ? this.value.toString().toUpperCase() : 'fa-external-link-square';
	}

	onClick() {
		this.save.emit(this.rowData);
	}
}
