
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const appointmentsQuery = gql`
query client(
	$appointmentId: Int
)
{
	appointments(apptId: $appointmentId)
	{
		apptId
		apptTitle
		apptStart
		apptEnd
		apptType
		apptAddons {
			serviceName
			servicePrice
			serviceLength
		}
		apptClient
		apptClientName
		apptClientNotes
		apptOwnNotes
		apptCancelled
		apptConfirmed
		apptCheckedout
		apptStatus
		apptPrice
		apptPaid
		apptOwed
		apptDeposit
		apptOrder
		apptOrderReceiptHash
		apptUser
		apptUrl
	}
}
`

const appointmentQuery = gql`
query client(
	$appointmentId: Int
)
{
	appointments(apptId: $appointmentId)
	{
		apptId
		apptTitle
		apptStart
		apptEnd
		apptType
		apptAddons {
			serviceName
			servicePrice
			serviceLength
		}
		apptClient
		apptClientName
		apptClientNotes
		apptOwnNotes
		apptCancelled
		apptConfirmed
		apptCheckedout
		apptStatus
		apptPrice
		apptPaid
		apptOwed
		apptDeposit
		apptOrder
		apptOrderReceiptHash
		apptUser
		apptUrl
	}
}
`

@Injectable()
export class AppointmentService implements OnDestroy {

	public loading = true;
	private sub: Subscription;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public getAppointment(appointmentId): Promise<any> {

		return new Promise((resolve, reject) => {

			this.sub = this.apollo.watchQuery({
				query: appointmentQuery,
				variables: {
					appointmentId: appointmentId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				resolve(data['appointments'][0]);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}
}
