import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { ServicesService } from '../../@core/data/services/services.service';
import { ClientService } from '../../@core/data/clients/client.service';
import { UserService } from '../../@core/data/users/user.service';
import { AvailabilityService } from '../../@core/data/availability/availability.service';
import { Subscription } from 'rxjs';
import { CompleterService, CompleterData, CompleterItem } from 'ng2-completer';
import { MailerService } from '../../@core/data/globals/mailer.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ClientAddComponent } from '../clients/clientadd/clientadd.component';
import { ModalService } from 'app/@core/data/globals/modal.service';


@Component({
	selector: 'ngx-appointmentadd',
	styleUrls: ['./appointmentAdd.component.scss'],
	template: `
	<div class="row">
		<div class="col-lg-12">
			<nb-card>
				<nb-card-header>
					New Appointment
					<button *ngIf="showClose" class="close" aria-label="Close" (click)="closeModal();">
						<span aria-hidden="true">&times;</span>
					</button>
				</nb-card-header>
				<nb-card-body>
					<form #tForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

						<div class="form-row">
							<div class="form-group col-md-10">
								<label for="client">Client</label>
								<div class="input-group mb-1">
									<ng2-completer #completer="ngModel" name="client" [inputName]="client" [inputClass]="(completer.valid) ? 'form-control' : 'form-control form-control-danger'" (selected)="onItemSelect($event)" [(ngModel)]="selections.selectedClientName" [datasource]="clientCompleter" [minSearchLength]="0" required></ng2-completer>
								</div>
							</div>
							<div class="form-group col-md-2">
								<button type="button" class="btn btn-icon btn-success h-80 w-100" (click)="newClient()">
									New
								</button>
							</div>
						</div>

						<div class="alert alert-danger" *ngIf="servicesList && servicesList.length === 0">
							You currently have no services setup, <strong><a (click)="nav('/pages/settings/booking/services')">Click Here</a></strong> to manage your services.
						</div>
						<div class="form-row">
							<div class="form-group col-md-10">
								<label>Service</label>
								<div class="input-group mb-1">
									<select *ngIf="servicesList" name="type" class="form-control" [(ngModel)]="selections.selectedService" (change)="onChange('service')" required>
										<option selected value style="display:none"></option>
										<option *ngFor="let r of servicesList" [attr.selected]="r?.serviceId === selections.selectedService?.serviceId ? true : null"
											[ngValue]="r"
										>
											{{r && r?.serviceName}} - ({{this.servicesService.serviceLengthToString(r.serviceLength)}}, £{{r && r?.serviceDeposit}}/£{{r && r?.servicePrice}})
										</option>
									</select>
								</div>
							</div>
							<div class="form-group col-md-2">
								<button type="button" class="btn btn-icon btn-success h-80 w-100" (click)="newService()">
									New
								</button>
							</div>
						</div>

						<label>Staff</label>
						<div class="input-group mb-1">
							<select *ngIf="userList" name="user" class="form-control" [(ngModel)]="selections.selectedUser" (change)="onChange('user')" required>
								<option selected value style="display:none"></option>
								<option *ngFor="let r of userList"
									[ngValue]="r"
								>
									{{r && r?.userUsername}}
								</option>
							</select>
						</div>

						<label>Title:</label>
						<div class="input-group mb-1">
							<input [(ngModel)]="event && event.title" type="text" class="form-control" name="title" required>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								 <label for="deposit">Deposit amount</label>
								<div class="input-group mb-1">
									<input
										[(ngModel)]="event.deposit"
										type="number" step="0.01" min="0"
										class="form-control"
										name="deposit"
									required>
								</div>
							</div>
							<div class="form-group col-md-6">
								<label for="price">Total price</label>
								<div class="input-group mb-1">
									<input
										[(ngModel)]="event.price"
										type="number" step="0.01" min="0"
										class="form-control"
										name="price"
									required>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="from">Start</label>
								<div class="input-group mb-1">
									<input *ngIf="event" mwlFlatpickr class="form-control"
										class="form-control"
										type="text"
										name="from"
										[(ngModel)]="event.start"
										altFormat="D J M Y, h:i K"
										dateFormat="Y-m-dTH:i"
									required>
								</div>
							</div>
							<div class="form-group col-md-6">
								<label for="to">End</label>
								<div class="input-group mb-1">
									<input *ngIf="event" mwlFlatpickr class="form-control"
										class="form-control"
										type="text"
										name="to"
										[(ngModel)]="event.end"
										altFormat="D J M Y, h:i K"
										dateFormat="Y-m-dTH:i"
									required>
								</div>
							</div>
						</div>

						<label>Notes</label>
						<div class="input-group mb-1">
							<input
								[(ngModel)]="event && event.notes"
								type="text"
								class="form-control"
								name="notes"
							>
						</div>

						<button type="submit" [disabled]="this.submitting" value="save" class="btn btn-primary">
							<i class="fa fa-floppy-o"></i> Save
						</button>

						<button type="button" class="btn btn-secondary">
							<input [(ngModel)]="event.sendReminder" name="sendReminder" type="checkbox">
							Send Reminder
						</button>
						<button type="button" class="btn btn-secondary">
							<input [(ngModel)]="event.autoDeposit" name="autoDeposit" type="checkbox">
							Deposit Already Paid
						</button>
					</form>
				</nb-card-body>
			</nb-card>
		</div>
	</div>
		`,
	styles: [
		`ng2-completer, ng2-completer input {
			display: block;
			width: 100%;
		}`,
		`ng2-completer input{
			padding: 0.75rem 1.125rem;
			color: #2a2a2a;
			background-color: #fff;
			border: 2px solid #dadfe6;
			font-family: Roboto;
			border-radius: .375rem;
		}`,
		`button {
			margin-top: 28px;
		}`,
		`label {
			margin-top: 8px;
		}`,
	],
	host: {
		'[style.display]': '"flex"',
		'[style.flex-direction]': '"column"',
		'[style.overflow]': '"hidden"',
	},
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentAddComponent implements OnInit, OnDestroy {

	//
	private clientSub: Subscription;
	protected clientCompleter: CompleterData;

	private serviceSub: Subscription;
	public servicesList = null;

	public userList = null;

	@Input()
	public event: any = {};

	@Input()
	public defaultDate: Date = new Date();

	@Output() formSubmit: EventEmitter<boolean> = new EventEmitter(false);
	@Input() showClose: boolean = false;

	// By default assume first result returned is selected:
	public selections = {
		selectedService: null,
		selectedClient: null,
		selectedClientName: null,
		selectedUser: null,
	}
	
	public submitting: boolean = false;

	constructor(
		public userService: UserService,
		private clientService: ClientService,
		private servicesService: ServicesService,
		private availabilityService: AvailabilityService,
		private completerService: CompleterService,
		private mailerService: MailerService,
		private modalService: ModalService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.clientService.getClients();
		this.servicesService.getServices();
	}

	ngOnInit() {
		// new, but confirmed event
		this.event.id = 0;
		this.event.confirmed = 1;
		this.event.start = (this.event.start) ? this.event.start : this.toDateTimeLocal(this.defaultDate);
		console.log('this.event', this.event);

		this.clientSub = this.clientService.clients.subscribe(value => {
			if (value) {
				// Consume
				this.clientCompleter = this.completerService.local(value, 'clientName', 'clientName');
			}
		});

		this.serviceSub = this.servicesService.services.subscribe(value => {
			if (value) {
				// Consume
				this.servicesList = value;
			}
		});

		this.userService.getUserList().then(userList => {
			this.userList = userList;
		})
	}


	public toDateTimeLocal(inputDate: Date): string {
		return this.availabilityService.toDateTimeLocal(inputDate);
	}

	public onItemSelect(selected: CompleterItem) {
		if (selected) {
			console.log('selected', selected)
			this.selections.selectedClient = selected.originalObject;
			this.event.client = this.selections.selectedClient.clientId;
			this.onChange('title');
		}
	}

	public newClient(): void {
		this.selections.selectedClient = {clientId: 0};
		this.onChange('client');
	}

	public newService(): void {
		this.selections.selectedService = {serviceId: 0};
		this.onChange('service');
	}

	public save(): void {

		this.submitting = true;
		let continueSave: boolean = true;
		if (!this.event.client) {
			window.alert('Please select a client.');
			continueSave = false;
		}

		if (!this.event.type) {
			window.alert('Please select a service.');
			continueSave = false;
		}

		this.userService.getUser(this.event.resourceId).then(userInfo => {

			const canPerform = (userInfo.userServicesList.find(svc => Number(svc.serviceId) === Number(this.event.type) && svc.serviceUserCanPerform));

			if (!canPerform) {
				if (!(confirm(`${userInfo.userUsername} does not provide this service, continue?`))) {
					continueSave = false;
				}
			}

			if (continueSave) {
				if (typeof this.event.start === 'object') {
					this.event.start = this.availabilityService.toDateTimeLocal(this.event.start);
				}

				if (typeof this.event.end === 'object') {
					this.event.end = this.availabilityService.toDateTimeLocal(this.event.end);
				}

				// explicitly set this to zero
				// this tells the api to create an order automatically
				this.event.order = 0;

				this.availabilityService.updateAppointment(this.event).then(newAppointment => {
					if (this.event.sendReminder) {
						console.log('newAppointment', newAppointment[0]);
						this.mailerService.sendEmail(newAppointment[0].apptClient, 'booking_email_body', newAppointment[0].apptOrder);
					}
					this.closeModal();
				});
			} else {
				// can't continue save, so no longer submitting
				this.submitting = false;
			}
		});
	}

	public closeModal(): void {
		this.formSubmit.next(false);
	}

	public nav(url: string) {
		this.router.navigateByUrl(url);
		this.closeModal();
	}

	public onChange (type: string): void {
		if (type === 'service') {

			if (this.selections.selectedService.serviceId === 0) {
				this.servicesService.customServiceForm().then( (result: any) => {
					this.servicesList[0] = result;
					this.selections.selectedService = this.servicesList[0];

					// Update info
					this.event.price = this.selections.selectedService.servicePrice;
					this.event.deposit = this.selections.selectedService.serviceDeposit;
					this.event.length = this.selections.selectedService.serviceLength;
					this.event.type = this.selections.selectedService.serviceId;
					this.event.end = moment(this.event.start).add(this.selections.selectedService.serviceLength, 'minutes').format('YYYY-MM-DD HH:mm:ss');
					console.log('this.event.start', this.event.start, moment(this.event.start), this.event.end);

				})
			} else {

				// Update info
				this.event.price = this.selections.selectedService.servicePrice;
				this.event.deposit = this.selections.selectedService.serviceDeposit;
				this.event.length = this.selections.selectedService.serviceLength;
				this.event.type = this.selections.selectedService.serviceId;
				this.event.end = moment(this.event.start).add(this.selections.selectedService.serviceLength, 'minutes').format('YYYY-MM-DD HH:mm:ss');
				console.log('this.event.start', this.event.start, moment(this.event.start), this.event.end);

			}
		} else if (type === 'client') {
			// 0 is placeholder for 'new'
			if (Number(this.selections.selectedClient.clientId) === 0) {
				this.modalService.dynamicModal(ClientAddComponent, {showClose: true}).then( (result: any) => {

					// Set selected client
					this.selections.selectedClient = result.updateClient[0];

					// Update name field
					this.selections.selectedClientName = this.selections.selectedClient.clientName;

					// blah
					this.event.client = this.selections.selectedClient.clientId;

				}).catch( errorMessage => {
					console.log('addClientForm: catch - ' + errorMessage);
				}).finally(() => {
					// there's a bug with the modalservice dropping the 'modal-open' class when closing any modals
					// this should only happen when the last modal is closed
					this.document.body.classList.add('modal-open');
				});
			} else {
				// Set selected client
				this.event.client = this.selections.selectedClient.clientId;
			}
		} else if (type === 'user') {
			this.event.resourceId = this.selections.selectedUser.userId;
		}

		console.log('this.selections', this.selections)
		// update title
		this.event.title = `${(this.selections.selectedClient && this.selections.selectedClient.clientName) ? this.selections.selectedClient.clientName : ''} ${(this.selections.selectedService) ? '- ' + this.selections.selectedService.serviceName : ''}${this.selections.selectedUser ? ' with ' + this.selections.selectedUser.userUsername : ''}`;
	}


	ngOnDestroy() {

		if (this.clientSub) {
			this.clientSub.unsubscribe();
		}

		if (this.serviceSub) {
			this.serviceSub.unsubscribe();
			this.servicesList = null;
		}
	}
}
