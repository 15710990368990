
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const query = gql`
{
  panels {
    label
    icon
    description
    count
  }
}
`

@Injectable()
export class PanelService implements OnDestroy {

	public loading = true;
	public panels = new BehaviorSubject<any>(null);
	private sub: Subscription;

	constructor(
			private apollo: Apollo,
	) {
		this.sub = this.apollo.watchQuery({
			query: query,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.panels.next(JSON.parse(JSON.stringify(data['panels'])));
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
