
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';

const categoryMutation = gql`
  mutation updateCategory(
		$apptcatId: Int,
		$apptcatName: String,
		$apptcatDescription: String,
		$apptcatEnabled: Int,
		$isDeleted: Int,
	) {
    	updateCategory(
			apptcatId: $apptcatId, apptcatName: $apptcatName,
			apptcatDescription: $apptcatDescription, apptcatEnabled: $apptcatEnabled,
			meta_isDeleted: $isDeleted
		) {
			apptcatId
			apptcatName
			apptcatDescription
			apptcatEnabled
			services {
				serviceId
				serviceCategory
				serviceSortorder
				serviceName
				serviceEnabled
			}
		}
  	}
`

@Injectable()
export class CategoriesService implements OnDestroy {

	public loading = true;
	public categories = new BehaviorSubject<any>(null);
	private sub: Subscription;

	public static lastVars = null;
	public static categoryQuery = gql`
	query categoryQuery(
		$categoryId: Int
	)
	{
		categories(categoryId: $categoryId)
		{
			apptcatId
			apptcatName
			apptcatDescription
			apptcatEnabled
			services {
				serviceId
				serviceCategory
				serviceSortorder
				serviceName
				serviceEnabled
				serviceIsAddon
				serviceLength
			}
		}
	}
`

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.sub = this.apollo.watchQuery({
			query: CategoriesService.categoryQuery,
			fetchPolicy: 'network-only',
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				try {
					const r = JSON.parse(JSON.stringify(data['categories']));
					this.categories.next(r);
				} catch (e) {
					alert(e + '\r\n' + JSON.stringify(data));
				}
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public getCategory(categoryId: Number = null): Promise<any> {

		CategoriesService.lastVars = {
			categoryId: categoryId,
		}

		return new Promise<any>((resolve, reject) => {
			this.sub = this.apollo.watchQuery({
				query: CategoriesService.categoryQuery,
				variables: CategoriesService.lastVars,
				fetchPolicy: 'network-only',
			}).valueChanges.subscribe(({data, loading}) => {
				if (data) {
					try {
						const r = JSON.parse(JSON.stringify(data['categories']));
						if (!CategoriesService.lastVars.categoryId) {
							this.categories.next(r);
						}
						resolve(r);
					} catch (e) {
						alert(e + '\r\n' + JSON.stringify(data));
						reject(e);
					}
					this.loading = loading;
				}
			});
		});

	}

	public updateCategory(category: any): Promise<any> {

		return new Promise<any>((resolve, reject) => {
			this.apollo.mutate({
				mutation: categoryMutation,
				variables: {
					apptcatId: category.apptcatId,
					apptcatName: category.apptcatName,
					apptcatDescription: category.apptcatDescription,
					apptcatEnabled: category.apptcatEnabled ? 1 : 0,
					isDeleted: category.isDeleted ? 1 : 0,
				},
				refetchQueries: [{
					query: CategoriesService.categoryQuery,
					variables: null, // CategoriesService.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data['updateCategory']);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
