import { Compiler, Component, Input, Injector,
					ViewChild, NgModule, NgModuleRef, ViewContainerRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';

@Component({
	selector: 'ngx-modal',
	template: `
		<div class="modal-header">
			<span>{{ modalHeader }}</span>
			<button *ngIf="showClose" class="close" aria-label="Close" (click)="closeModal()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<ng-container #vc></ng-container>
		</div>
		<div class="modal-footer">
			<button *ngIf="showClose" class="btn btn-md btn-primary" (click)="closeModal()">{{modalButton}}</button>
		</div>
	`,
	styles: [`
		.modal-body input.form-control{
			font-size: 1.75rem;
		}
	`],
})

export class ModalComponent implements AfterViewInit {

	@Input() modalHeader: string = '';
	@Input() modalContent: string = '';
	@Input() modalButton: string = '';
	@Input() showClose: boolean = true;
	static callBackFunc: (element, args: any, evt) => void;
	static contextRef: any = null;
	@Output() onClose = new EventEmitter<any>();
	@ViewChild('vc', {read: ViewContainerRef}) vc;

	constructor(
		private activeModal: NgbActiveModal,
		private _compiler: Compiler,
		private _injector: Injector,
		private _m: NgModuleRef<any>,
	) {}

	ngAfterViewInit() {
		const tmpCmp = Component({moduleId: module.id, template: this.modalContent})(
			class {
				public contextRef = ModalComponent.contextRef;
				public callback(element = null, args = null, evt = null) {
					if (evt) { evt.preventDefault(); };
					ModalComponent.callBackFunc(element, args, evt);
				}
			},
		);

		const tmpModule = NgModule({exports: [], declarations: [tmpCmp], imports: [FormsModule, FlatpickrModule, CommonModule], entryComponents: []})(class {});

		this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
			.then((factories) => {
				const f = factories.componentFactories[0];
				const cmpRef = f.create(this._injector, [], null, this._m);
				cmpRef.instance.name = 'dynamic';
				this.vc.insert(cmpRef.hostView);
			})
	}

	closeModal() {
		this.onClose.emit();
		this.activeModal.close();
	}
}
