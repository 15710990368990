import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import { NgxLoginComponent, NgxRegisterComponent, NgxRequestPasswordComponent,
		NgxLogoutComponent, NgxResetPasswordComponent } from './@theme/components/auth';
// import { UnsavedChangesGuard } from './unsaved-changes-guard.service';

const routes: Routes = [
	{
		path: 'pages',
		loadChildren: 'app/pages/pages.module#PagesModule',
		canActivate: [AuthGuard],
		// canDeactivate: [UnsavedChangesGuard],
	},
	{
		path: 'auth',
		children: [
			{
				path: '',
				component: NgxLoginComponent,
			},
			{
				path: 'login',
				component: NgxLoginComponent,
			},
			{
				path: 'login/:loginBusiness',
				component: NgxLoginComponent,
			},
			{
				path: 'login/:loginBusiness/:loginUsername',
				component: NgxLoginComponent,
			},
			{
				path: 'register',
				component: NgxRegisterComponent,
			},
			{
				path: 'logout',
				component: NgxLogoutComponent,
			},
			{
				path: 'request-password',
				component: NgxRequestPasswordComponent,
			},
			{
				path: 'reset-password',
				component: NgxResetPasswordComponent,
			},
			{
				path: 'reset-password/:resetToken',
				component: NgxResetPasswordComponent,
			},
		],
	},
	{ path: '', redirectTo: 'pages', pathMatch: 'full' },
	{ path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
	useHash: true,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor() {}
}
