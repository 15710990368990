
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';

const query = gql`
{
    usergroups {
        usergroupId
		usergroupName
		usergroupSignin
		usergroupEditAlerts
		usergroupAddAlerts
		usergroupViewAlerts
		usergroupViewAllAlerts
		usergroupAddAppointments
		usergroupViewAppointments
		usergroupEditAppointments
		usergroupAddCategories
		usergroupEditCategories
		usergroupViewCategories
		usergroupAddClients
		usergroupEditClients
		usergroupViewClients
		usergroupEditCustomers
		usergroupViewCustomers
		usergroupAddLeave
		usergroupEditLeave
		usergroupViewLeave
		usergroupAddStoreorders
		usergroupEditStoreorders
		usergroupViewStoreorders
		usergroupAddPayments
		usergroupViewPayments
		usergroupEditPayments
		usergroupViewReports
		usergroupEditReports
		usergroupAddReports
		usergroupViewStoreitems
		usergroupEditStoreitems
		usergroupAddStoreitems
		usergroupAddServices
		usergroupEditServices
		usergroupViewServices
		usergroupAddUsergroups
		usergroupEditUsergroups
		usergroupViewUsergroups
		usergroupAddUsers
		usergroupEditUsers
		usergroupViewUsers
		usergroupEditVouchers
		usergroupAddVouchers
		usergroupViewVouchers
		usergroupEditsystemsettings
		usergroupViewsystemsettings
		usergroupEditmessageconfigs
		usergroupViewmessageconfigs
		usergroupEditmessagetemplates
		usergroupViewmessagetemplates
		usergroupEditbusinesssettings
		usergroupViewbusinesssettings
		usergroupEditAvailability
		usergroupViewAvailability
		usergroupAddAvailability
		usergroupEditallcalendars
		usergroupViewallcalendars
		usergroupViewanalytics
    }
}
`

const usergroupMutation = gql`
  	mutation updateUsergroup(
		$usergroupId: Int,
		$usergroupName: String,
		$usergroupSignin: Int,
		$usergroupEditAlerts: Int,
		$usergroupAddAlerts: Int,
		$usergroupViewAlerts: Int,
		$usergroupViewAllAlerts: Int,
		$usergroupAddAppointments: Int,
		$usergroupViewAppointments: Int,
		$usergroupEditAppointments: Int,
		$usergroupAddCategories: Int,
		$usergroupEditCategories: Int,
		$usergroupViewCategories: Int,
		$usergroupAddClients: Int,
		$usergroupEditClients: Int,
		$usergroupViewClients: Int,
		$usergroupEditCustomers: Int,
		$usergroupViewCustomers: Int,
		$usergroupAddLeave: Int,
		$usergroupEditLeave: Int,
		$usergroupViewLeave: Int,
		$usergroupAddStoreorders: Int,
		$usergroupEditStoreorders: Int,
		$usergroupViewStoreorders: Int,
		$usergroupAddPayments: Int,
		$usergroupViewPayments: Int,
		$usergroupEditPayments: Int,
		$usergroupViewReports: Int,
		$usergroupEditReports: Int,
		$usergroupAddReports: Int,
		$usergroupViewStoreitems: Int,
		$usergroupEditStoreitems: Int,
		$usergroupAddStoreitems: Int,
		$usergroupAddServices: Int,
		$usergroupEditServices: Int,
		$usergroupViewServices: Int,
		$usergroupAddUsergroups: Int,
		$usergroupEditUsergroups: Int,
		$usergroupViewUsergroups: Int,
		$usergroupAddUsers: Int,
		$usergroupEditUsers: Int,
		$usergroupViewUsers: Int,
		$usergroupEditVouchers: Int,
		$usergroupAddVouchers: Int,
		$usergroupViewVouchers: Int,
		$usergroupEditsystemsettings: Int,
		$usergroupViewsystemsettings: Int,
		$usergroupEditmessageconfigs: Int,
		$usergroupViewmessageconfigs: Int,
		$usergroupEditmessagetemplates: Int,
		$usergroupViewmessagetemplates: Int,
		$usergroupEditbusinesssettings: Int,
		$usergroupViewbusinesssettings: Int,
		$usergroupEditAvailability: Int,
		$usergroupViewAvailability: Int,
		$usergroupAddAvailability: Int,
		$usergroupEditallcalendars: Int,
		$usergroupViewallcalendars: Int,
		$usergroupViewanalytics: Int
	) {
    	updateUsergroup(
			usergroupId: $usergroupId,
			usergroupName: $usergroupName,
			usergroupSignin: $usergroupSignin,
			usergroupEditAlerts: $usergroupEditAlerts,
			usergroupAddAlerts: $usergroupAddAlerts,
			usergroupViewAlerts: $usergroupViewAlerts,
			usergroupViewAllAlerts: $usergroupViewAllAlerts,
			usergroupAddAppointments: $usergroupAddAppointments,
			usergroupViewAppointments: $usergroupViewAppointments,
			usergroupEditAppointments: $usergroupEditAppointments,
			usergroupAddCategories: $usergroupAddCategories,
			usergroupEditCategories: $usergroupEditCategories,
			usergroupViewCategories: $usergroupViewCategories,
			usergroupAddClients: $usergroupAddClients,
			usergroupEditClients: $usergroupEditClients,
			usergroupViewClients: $usergroupViewClients,
			usergroupEditCustomers: $usergroupEditCustomers,
			usergroupViewCustomers: $usergroupViewCustomers,
			usergroupAddLeave: $usergroupAddLeave,
			usergroupEditLeave: $usergroupEditLeave,
			usergroupViewLeave: $usergroupViewLeave,
			usergroupAddStoreorders: $usergroupAddStoreorders,
			usergroupEditStoreorders: $usergroupEditStoreorders,
			usergroupViewStoreorders: $usergroupViewStoreorders,
			usergroupAddPayments: $usergroupAddPayments,
			usergroupViewPayments: $usergroupViewPayments,
			usergroupEditPayments: $usergroupEditPayments,
			usergroupViewReports: $usergroupViewReports,
			usergroupEditReports: $usergroupEditReports,
			usergroupAddReports: $usergroupAddReports,
			usergroupViewStoreitems: $usergroupViewStoreitems,
			usergroupEditStoreitems: $usergroupEditStoreitems,
			usergroupAddStoreitems: $usergroupAddStoreitems,
			usergroupAddServices: $usergroupAddServices,
			usergroupEditServices: $usergroupEditServices,
			usergroupViewServices: $usergroupViewServices,
			usergroupAddUsergroups: $usergroupAddUsergroups,
			usergroupEditUsergroups: $usergroupEditUsergroups,
			usergroupViewUsergroups: $usergroupViewUsergroups,
			usergroupAddUsers: $usergroupAddUsers,
			usergroupEditUsers: $usergroupEditUsers,
			usergroupViewUsers: $usergroupViewUsers,
			usergroupEditVouchers: $usergroupEditVouchers,
			usergroupAddVouchers: $usergroupAddVouchers,
			usergroupViewVouchers: $usergroupViewVouchers,
			usergroupEditsystemsettings: $usergroupEditsystemsettings,
			usergroupViewsystemsettings: $usergroupViewsystemsettings,
			usergroupEditmessageconfigs: $usergroupEditmessageconfigs,
			usergroupViewmessageconfigs: $usergroupViewmessageconfigs,
			usergroupEditmessagetemplates: $usergroupEditmessagetemplates,
			usergroupViewmessagetemplates: $usergroupViewmessagetemplates,
			usergroupEditbusinesssettings: $usergroupEditbusinesssettings,
			usergroupViewbusinesssettings: $usergroupViewbusinesssettings,
			usergroupEditAvailability: $usergroupEditAvailability,
			usergroupViewAvailability: $usergroupViewAvailability,
			usergroupAddAvailability: $usergroupAddAvailability,
			usergroupEditallcalendars: $usergroupEditallcalendars,
			usergroupViewallcalendars: $usergroupViewallcalendars,
			usergroupViewanalytics: $usergroupViewanalytics
		){
			usergroupId
			usergroupName
			usergroupSignin
			usergroupEditAlerts
			usergroupAddAlerts
			usergroupViewAlerts
			usergroupViewAllAlerts
			usergroupAddAppointments
			usergroupViewAppointments
			usergroupEditAppointments
			usergroupAddCategories
			usergroupEditCategories
			usergroupViewCategories
			usergroupAddClients
			usergroupEditClients
			usergroupViewClients
			usergroupEditCustomers
			usergroupViewCustomers
			usergroupAddLeave
			usergroupEditLeave
			usergroupViewLeave
			usergroupAddStoreorders
			usergroupEditStoreorders
			usergroupViewStoreorders		
			usergroupAddPayments
			usergroupViewPayments
			usergroupEditPayments
			usergroupViewReports
			usergroupEditReports
			usergroupAddReports
			usergroupViewStoreitems
			usergroupEditStoreitems
			usergroupAddStoreitems
			usergroupAddServices
			usergroupEditServices
			usergroupViewServices
			usergroupAddUsergroups
			usergroupEditUsergroups
			usergroupViewUsergroups
			usergroupAddUsers
			usergroupEditUsers
			usergroupViewUsers
			usergroupEditVouchers
			usergroupAddVouchers
			usergroupViewVouchers
			usergroupEditsystemsettings
			usergroupViewsystemsettings
			usergroupEditmessageconfigs
			usergroupViewmessageconfigs
			usergroupEditmessagetemplates
			usergroupViewmessagetemplates
			usergroupEditbusinesssettings
			usergroupViewbusinesssettings
			usergroupEditAvailability
			usergroupViewAvailability
			usergroupAddAvailability
			usergroupEditallcalendars
			usergroupViewallcalendars
			usergroupViewanalytics
		}
  	}
`

@Injectable()
export class UsergroupService implements OnDestroy {

	public loading = true;
	public usergroups = new BehaviorSubject<any>(null);
	private sub: Subscription;

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {
		this.sub = this.apollo.watchQuery({
			query: query,
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			this.usergroups.next(JSON.parse(JSON.stringify(data['usergroups'])));
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	updateUsergroup(usergroup: any, meta_isDeleted = false): void {

		this.apollo.mutate({
			mutation: usergroupMutation,
			variables: {
				usergroupId: usergroup.usergroupId,
				usergroupName: usergroup.usergroupName,
				usergroupSignin: usergroup.usergroupSignin ? 1 : 0,
				usergroupEditAlerts: usergroup.usergroupEditAlerts ? 1 : 0,
				usergroupAddAlerts: usergroup.usergroupAddAlerts ? 1 : 0,
				usergroupViewAlerts: usergroup.usergroupViewAlerts ? 1 : 0,
				usergroupViewAllAlerts: usergroup.usergroupViewAllAlerts ? 1 : 0,
				usergroupAddAppointments: usergroup.usergroupAddAppointments ? 1 : 0,
				usergroupViewAppointments: usergroup.usergroupViewAppointments ? 1 : 0,
				usergroupEditAppointments: usergroup.usergroupEditAppointments ? 1 : 0,
				usergroupAddCategories: usergroup.usergroupAddCategories ? 1 : 0,
				usergroupEditCategories: usergroup.usergroupEditCategories ? 1 : 0,
				usergroupViewCategories: usergroup.usergroupViewCategories ? 1 : 0,
				usergroupAddClients: usergroup.usergroupAddClients ? 1 : 0,
				usergroupEditClients: usergroup.usergroupEditClients ? 1 : 0,
				usergroupViewClients: usergroup.usergroupViewClients ? 1 : 0,
				usergroupEditCustomers: usergroup.usergroupEditCustomers ? 1 : 0,
				usergroupViewCustomers: usergroup.usergroupViewCustomers ? 1 : 0,
				usergroupAddLeave: usergroup.usergroupAddLeave ? 1 : 0,
				usergroupEditLeave: usergroup.usergroupEditLeave ? 1 : 0,
				usergroupViewLeave: usergroup.usergroupViewLeave ? 1 : 0,
				usergroupAddStoreorders: usergroup.usergroupAddStoreorders ? 1 : 0,
				usergroupEditStoreorders: usergroup.usergroupEditStoreorders ? 1 : 0,
				usergroupViewStoreorders: usergroup.usergroupViewStoreorders ? 1 : 0,
				usergroupAddPayments: usergroup.usergroupAddPayments ? 1 : 0,
				usergroupViewPayments: usergroup.usergroupViewPayments ? 1 : 0,
				usergroupEditPayments: usergroup.usergroupEditPayments ? 1 : 0,
				usergroupViewReports: usergroup.usergroupViewReports ? 1 : 0,
				usergroupEditReports: usergroup.usergroupEditReports ? 1 : 0,
				usergroupAddReports: usergroup.usergroupAddReports ? 1 : 0,
				usergroupViewStoreitems: usergroup.usergroupViewStoreitems ? 1 : 0,
				usergroupEditStoreitems: usergroup.usergroupEditStoreitems ? 1 : 0,
				usergroupAddStoreitems: usergroup.usergroupAddStoreitems ? 1 : 0,
				usergroupAddServices: usergroup.usergroupAddServices ? 1 : 0,
				usergroupEditServices: usergroup.usergroupEditServices ? 1 : 0,
				usergroupViewServices: usergroup.usergroupViewServices ? 1 : 0,
				usergroupAddUsergroups: usergroup.usergroupAddUsergroups ? 1 : 0,
				usergroupEditUsergroups: usergroup.usergroupEditUsergroups ? 1 : 0,
				usergroupViewUsergroups: usergroup.usergroupViewUsergroups ? 1 : 0,
				usergroupAddUsers: usergroup.usergroupAddUsers ? 1 : 0,
				usergroupEditUsers: usergroup.usergroupEditUsers ? 1 : 0,
				usergroupViewUsers: usergroup.usergroupViewUsers ? 1 : 0,
				usergroupEditVouchers: usergroup.usergroupEditVouchers ? 1 : 0,
				usergroupAddVouchers: usergroup.usergroupAddVouchers ? 1 : 0,
				usergroupViewVouchers: usergroup.usergroupViewVouchers ? 1 : 0,
				usergroupEditsystemsettings: usergroup.usergroupEditsystemsettings ? 1 : 0,
				usergroupViewsystemsettings: usergroup.usergroupViewsystemsettings ? 1 : 0,
				usergroupEditmessageconfigs: usergroup.usergroupEditmessageconfigs ? 1 : 0,
				usergroupViewmessageconfigs: usergroup.usergroupViewmessageconfigs ? 1 : 0,
				usergroupEditmessagetemplates: usergroup.usergroupEditmessagetemplates ? 1 : 0,
				usergroupViewmessagetemplates: usergroup.usergroupViewmessagetemplates ? 1 : 0,
				usergroupEditbusinesssettings: usergroup.usergroupEditbusinesssettings ? 1 : 0,
				usergroupViewbusinesssettings: usergroup.usergroupViewbusinesssettings ? 1 : 0,
				usergroupEditAvailability: usergroup.usergroupEditAvailability ? 1 : 0,
				usergroupViewAvailability: usergroup.usergroupViewAvailability ? 1 : 0,
				usergroupAddAvailability: usergroup.usergroupAddAvailability ? 1 : 0,
				usergroupEditallcalendars: usergroup.usergroupEditallcalendars ? 1 : 0,
				usergroupViewallcalendars: usergroup.usergroupViewallcalendars ? 1 : 0,
				usergroupViewanalytics: usergroup.usergroupViewanalytics ? 1 : 0,
				meta_isDeleted: meta_isDeleted ? 1 : 0,
			},
		}).subscribe(({ data }) => {
			console.log('got data', data);
			this.toastr.success('Changes Saved');
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error saving changes', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
