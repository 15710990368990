
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ModalService } from '../globals/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const clientQuery = gql`
query client(
	$clientId: Int
)
{
	clients(clientId: $clientId) {
		clientId
		clientName
		clientNumber
		clientEmail
		clientAddress
		clientNotes
		clientStripeId
		upcomingAppointments {
			apptId
			apptStart
			apptTitle
		},
		appointmentHistory{
			apptId
			apptStart
			apptTitle
		},
		orderHistory{
			storeorderId
			storeorderDate
			storeorderStatus
		}
	}
}
`

const clientAppointmentsQuery = gql`
query client(
	$clientId: Int
)
{
	clients(clientId: $clientId) {
		clientId
		upcomingAppointments {
			apptId
			apptStart
			apptTitle
		},
		appointmentHistory{
			apptId
			apptStart
			apptTitle
		}
	}
}
`

const clientsQuery = gql`
query {
  clients {
    clientId
    clientName
    clientNumber
  }
}
`

const clientMutation = gql`
  mutation updateClient(
			$clientId: Int, $clientName: String!, $clientNumber: String!,
			$clientEmail: String!, $clientAddress: String!, $clientNotes: String, $meta_isDeleted: Int
		) {
    	updateClient(
			clientId: $clientId, clientName: $clientName, clientNumber: $clientNumber,
			clientEmail: $clientEmail, clientAddress: $clientAddress, clientNotes: $clientNotes, meta_isDeleted: $meta_isDeleted
		)
		{
			clientId,
			clientName,
			clientNumber,
			clientEmail,
			clientAddress,
			clientNotes
		}
  	}
`

@Injectable()
export class ClientService implements OnDestroy {

	public loading = true;
	public clients = new BehaviorSubject<any>(null);
	public client = new BehaviorSubject<any>(null);
	public clientAppointments = new BehaviorSubject<any>(null);
	private sub: Subscription;

	private lastVars: any = {};

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
		private modalService: ModalService,
	) {}

	public clearClient(): void {
		this.clients = new BehaviorSubject<any>(null);
	}

	public getClient(clientId): Promise<any> {

		return new Promise((resolve, reject) => {

			this.sub = this.apollo.watchQuery({
				query: clientQuery,
				variables: {
					clientId: clientId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				this.client.next(data['clients'][0]);
				resolve(this.client.value);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public getAppointments(clientId): Promise<any> {

		return new Promise((resolve, reject) => {
			this.sub = this.apollo.watchQuery({
				query: clientAppointmentsQuery,
				variables: {
					clientId: clientId,
				},
			}).valueChanges.subscribe(({data, loading}) => {
				this.loading = loading;
				resolve(data['clients'][0]);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public getClients(): Promise<any> {

		return new Promise((resolve, reject) => {
			this.sub = this.apollo.watchQuery({
				query: clientsQuery,
				variables: this.lastVars,
			}).valueChanges.subscribe(({data, loading}) => {
				console.log('getclients done')
				this.loading = loading;
				this.clients.next(JSON.parse(JSON.stringify(data['clients'])));
				resolve(this.clients.value);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error retrieving data', error);
				reject(error);
			});
		});
	}

	public updateClient(client: any, meta_isDeleted = false) {

		return new Promise((resolve, reject) => {
			this.apollo.mutate({
				mutation: clientMutation,
				variables: {
					clientId: client.clientId,
					clientName: client.clientName,
					clientEmail: client.clientEmail,
					clientNumber: client.clientNumber,
					clientAddress: client.clientAddress,
					clientNotes: client.clientNotes,
					meta_isDeleted: meta_isDeleted ? 1 : 0,
				},
				refetchQueries: [{
					query: clientsQuery,
					variables: this.lastVars,
				}],
			}).subscribe(({ data }) => {
				console.log('got data', data);
				this.toastr.success('Changes Saved');
				resolve(data);
			}, (error) => {
				console.log(error);
				this.toastr.warning('There was an error saving changes', error);
				reject(error);
			});

		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
		this.clearClient();
	}
}
