import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../@core/data/globals/search.service';

@Component({
	selector: 'ngx-search-result-view',
	template: `
	<style>
		i.eva.eva-close-circle-outline{
			float: right;
			font-size: 2em;
		}
	</style>
	<nb-layout-column class="main-content" *ngIf="searchResultsData.length > 0">
		<div class="row">
			<div class="col-lg-12">
				<nb-layout-column class="colored-column-basic">
					<nb-card accent="info">
						<nb-card-header>Results for: {{ searchTerm || '-' }} <i (click)="clearResults()" class="eva eva-close-circle-outline"></i></nb-card-header>
						<nb-card-body>
							<select *ngIf="getResultTypes().length > 1" class="form-control" (change)="filterResultType($event.target.value)">
								<option value="reset">All</option>
								<option *ngFor="let searchResultType of getResultTypes()">{{searchResultType}}</option>
							</select>
							<ng2-smart-table [settings]="resultViewTable" [source]="searchResults" (deleteConfirm)="onDeleteConfirm($event)" (userRowSelect)="onCustomAction($event)" (custom)="onCustomAction($event)">
							</ng2-smart-table>
						</nb-card-body>
					</nb-card>
				</nb-layout-column>
			</div>
		</div>
		<ng-content select="router-outlet"></ng-content>
	</nb-layout-column>
	`,
})
export class SearchResultViewComponent implements OnInit, OnDestroy {

	// Set up Getter/Setter for events
	private _searchTerm: string;
	public get searchTerm(): string {
		return this._searchTerm;
	}
	@Input()
	public set searchTerm(value: string) {
		if (this._searchTerm !== value) {
			this._searchTerm = value;
			this.searchService.search(this.searchTerm);
		}
	}

	private dataSub: Subscription;
	public searchResultsData = [];
	public searchResults: LocalDataSource = new LocalDataSource();

	resultViewTable = {
		actions: {
			custom: [
				{
					name: 'Open',
					title: '<i class="fa fa-external-link-square" title="Open"></i>',
				},
			],
			add: false,
			edit: false,
			delete: false,
			position: 'right',
		},
		hideSubHeader: true,
		columns: {
			searchResultTitle: {
				title: 'Result Name',
				type: 'string',
			},
		},
	};

	constructor(
		private searchService: SearchService,
	) {}

	public ngOnInit(): void {
		this.dataSub = this.searchService.results.subscribe(value => {
			if (value) {
				this.searchResultsData = value;
				this.searchResults.load(this.searchResultsData);
			}
		});
	}

	public ngOnDestroy(): void {
		this.clearResults();
		if (this.dataSub) { this.dataSub.unsubscribe(); }
	}

	public getResultTypes(): any[] {
		return ([...new Set(this.searchResultsData.map(searchResult => searchResult.searchResultType))]);
	}

	public filterResultType(searchResultType) {

		if (searchResultType === 'reset') {
			this.searchResults.setFilter([], false);
			return true;
		}

		this.searchResults.setFilter([{field: 'searchResultType', search: searchResultType}], false);
	}

	public onCustomAction(event): void {
		console.log('onCustomAction', event.data);
		this.clearResults();

		location.href = event.data.searchResultLink + event.data.searchResultId;
	}

	public clearResults(): void {
		this.searchTerm = null;
		this.searchResultsData = []
		this.searchResults.load(this.searchResultsData);
	}

}
