
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';


const searchQuery = gql`
	query search($searchString: String) {
		search(searchString: $searchString) {
			searchResultTitle
			searchResultType
			searchResultSortField
			searchResultLink
			searchResultId
		}
	}
`

@Injectable()
export class SearchService implements OnDestroy {

	public loading = true;
	private sub: Subscription;
	public results = new BehaviorSubject<any>([]);

	constructor(
		private apollo: Apollo,
		private toastr: ToastrService,
	) {}

	public search(searchString: String): any {

		if (!searchString) { return false; }

		console.log('searching for: ', searchString)

		this.sub = this.apollo.watchQuery({
			query: searchQuery,
			variables: {
				searchString: searchString,
			},
		}).valueChanges.subscribe(({data, loading}) => {
			this.loading = loading;
			if (data) {
				console.log(data['search']);
				this.results.next(data['search']);
			}
		}, (error) => {
			console.log(error);
			this.toastr.warning('There was an error retrieving data', error);
		});
	}

	public ngOnDestroy(): void {
		if (this.sub) { this.sub.unsubscribe(); };
	}
}
